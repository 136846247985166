// Tabs layout

.tabs__container .tabs ul > li > a {
  border: 0 none;
}

.tabs {
  a {
    font: 700 0.9375rem/1.06 $font-header;
    color: $purple;
    padding: 0;
    @media screen and (max-width: 320px) {
      padding: 0 1.75em;
    }
    height: 100%;
    white-space: normal;
    text-align: center;
    .availability-status {
      position: relative;
      left: 10px;
      + div {
        position: relative;
        left: 10px;
      }
    }
  }

  li {
    border-top: 1px solid $purple;
    border-bottom: 1px solid $purple;
    border-right: 1px solid $purple;
    height: 3rem;
    flex: 1 1 0;
    width: 0;
    background: white;
    &:first-child {
      border-radius: $radius-rounded 0 0 $radius-rounded;
      border-left: 1px solid $purple;
    }
    &:last-child {
      border-radius: 0 $radius-rounded $radius-rounded 0;
    }
    &:hover,
    &:focus {
      a {
        border-bottom: 0 none;
      }
    }

    &.is-active {
      background: $purple;
      a {
        color: white;
        border-bottom: 0 none;
      }
    }
  }
}

// changes from horizontal to vertical at breakpoint
// ------------------------------------------------

.section--tabs-vertical {
  .tabs__container {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    @include tablet {
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.09);
      border: solid 1px #ededed;
      display: flex;
      margin-left: 0;
      margin-right: 0;
    }
    .tabs {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      @include tablet {
        padding-left: 0;
        padding-right: 0;
        width: 200px;
        flex-shrink: 0;
        margin-bottom: 0;
      }
      @include desktop {
        width: 260px;
      }
      ul {
        @include tablet {
          overflow: hidden;
          width: 100%;
          flex-direction: column;
          height: auto;
          justify-content: flex-start; // tabs remain fixed to the top
        }
        li {
          @include tablet {
            height: auto;
            min-height: 98px;
            width: 100%;
            flex-shrink: 0;
            flex-basis: auto;
            flex-grow: 0; // tabs are a fixed height
            border-radius: 0;
            border-color: transparent;
          }
          &:first-child {
            a {
              padding-left: 0.7em;
              // specific for small mobile screens
              @media screen and (max-width: 413px) {
                padding-left: 0.5em;
              }
            }
          }
          a {
            background-color: transparent;
            display: flex;
            flex-direction: column;
            //align-items: center;
            justify-content: center;
            font: 700 1rem/1.2 $font-header;

            align-items: normal;
            text-align: left;
            padding: 0 0.8em;
            // specific for small mobile screens
            @media screen and (max-width: 413px) {
              padding: 0 0.25em;
              font-size: 14px;
            }

            @include tablet {
              align-items: flex-start;
              justify-content: flex-start;
              width: 100%;
              font-size: 1.25rem;
              height: 100%;
              color: $cochlear-black;
              position: relative;
              padding: 2em 1em 1em;
              &:hover,
              &:focus {
                background-color: #f6f6f6;
              }
            }
            span {
              font: 400 0.5rem/1.1 $font-primary;
              white-space: nowrap;
              @include tablet {
                font-size: 1rem;
              }
            }
          }
          &.is-active {
            @include tablet {
              background: $yellow-highlight;
            }
            a {
              &:hover,
              &:focus {
                background-color: transparent;
              }
              @include tablet {
                &:after {
                  content: '';
                  display: block;
                  position: absolute;
                  width: 0;
                  height: 0;
                  border-top: 10px solid transparent;
                  border-bottom: 10px solid transparent;
                  border-right: 9px solid #fff;
                  right: -2px;
                  top: 50%;
                  margin-top: 0;
                }
              }
            }
          }
          + li {
            @include tablet {
              margin-left: 0;
            }
          }
        }
      }

      // @include tablet {
      //   width: 200px;
      //   flex-shrink: 0;
      //   margin-bottom: 0;
      // }
      // @include desktop {
      //   width: 260px;
      // }
      // ul {
      //   overflow: hidden;
      //   height: 65px;
      //   justify-content: center;
      //   width: 100%;
      //   @include tablet {
      //     flex-direction: column;
      //     height: auto;
      //     justify-content: flex-start; // tabs remain fixed to the top
      //   }
      //   li { // these get assigned the 'is-active' class
      //     height:100%;
      //     flex-grow: 1;
      //     flex-shrink: 0;
      //     width: 33.33%;
      //     flex-basis: 33.33%;
      //     @include tablet {
      //       height: auto;
      //       min-height:98px;
      //       width: 100%;
      //       flex-basis: auto;
      //       flex-grow: 0; // tabs are a fixed height
      //     }
      //     a {
      //       height: 100%;
      //       font: 700 1rem/1.2 $font-header;
      //       color: $cochlear-black;
      //       position: relative;
      //       display: flex;
      //       flex-direction: column;
      //       align-items: flex-start;
      //       padding: 0.8em;
      //       span {
      //          font: 400 0.6875rem/1.1 $font-primary;
      //          @include tablet {
      //            font-size: 0.875rem;
      //          }
      //          @include desktop {
      //           font-size: 1rem;
      //         }
      //       }
      //       @include tablet {
      //         width: 100%;
      //         font-size: 1.25rem;
      //         justify-content: flex-start;
      //         padding: 2em 1em 1em;
      //       }
      //     }
      //     &.is-active {
      //       a {
      //         @include tablet {
      //           &:after {
      //             content: '';
      //             display: block;
      //             position: absolute;
      //             width: 0;
      //             height: 0;
      //             border-top: 10px solid transparent;
      //             border-bottom: 10px solid transparent;
      //             border-right: 9px solid #fff;
      //             right: -1px;
      //             top: 50%;
      //             margin-top: 0;
      //           }
      //         }
      //       }
      //     }
      //     + li {
      //       @include tablet {
      //         margin-left: 0;
      //       }
      //     }
      //   }
      // }
      &__content {
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.09);
        @include tablet {
          box-shadow: none;
        }
        // border: solid 1px #ededed;
        width: 100%;
        display: flex;
        flex-direction: column;
        &__list {
          padding: 0 0.625rem;
          flex-grow: 1;
          margin-bottom: 2rem;
          .articles__list__item {
            padding: 1rem 0.625rem 0;
          }
        }
        &__footer {
          height: auto;
          border: 0;
          border-top: 1px solid #efeeed;
          margin-top: 2rem;
          padding: 0;
          display: block;
          > a {
            text-align: left;
            color: $link-colour;
            font: 700 1rem/1.88 $font-primary;
            display: block;
            padding: 19px 60px 22px calc(2rem - 3px);
            border-left: 3px solid $primary;
            position: relative;
            &:hover,
            &:focus {
              color: $link-hover;
            }
            @include tablet {
              font: 700 1.125rem/1.67 $font-primary;
              margin-right: 1rem;
              // right chevron
              &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 30px;
                margin: -8px 0 0 0;
                width: 20px;
                height: 20px;
                display: block;
                background: url('./../../assets/icons/Icon_Purple_Chevron.svg')
                  no-repeat 0 0;
                transform: rotate(-90deg);
                transition: all 0.2s ease-in-out;
              }
              &:hover,
              &:focus {
                &:after {
                  right: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.section--help-columns {
  .tabs {
    @include desktop {
      display: none;
    }
  }
}

.site__content > .section + .section.section--identify-serial-number {
  @include touch {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.section--identify-serial-number {
  .tabs__container {
    @include touch {
      box-shadow: none;
      border: 0;
    }
    .tabs__content__list {
      @include touch {
        margin-bottom: 4rem;
      }
      @include desktop {
        padding: 2rem 2rem 0;
      }
      h2.title {
        @include desktop {
          display: none;
        }
      }
    }
  }
  .tabs {
    display: none;
    @include desktop {
      display: flex;
    }
  }
}
