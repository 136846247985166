// Font src location relative to the main.scss which imports the fonts
$fontsPath: './../fonts/';

@font-face {
  font-family: 'BlissPro-Regular';
  src: url('#{$fontsPath}BlissPro-Regular.eot');
  src: url('#{$fontsPath}BlissPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontsPath}BlissPro-Regular.woff2') format('woff2'),
    url('#{$fontsPath}BlissPro-Regular.woff') format('woff'),
    url('#{$fontsPath}BlissPro-Regular.svg#BlissPro-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BlissPro-Light';
  src: url('#{$fontsPath}BlissPro-Light.eot');
  src: url('#{$fontsPath}BlissPro-Light.eot?#iefix')
      format('embedded-opentype'),
    url('#{$fontsPath}BlissPro-Light.woff2') format('woff2'),
    url('#{$fontsPath}BlissPro-Light.woff') format('woff'),
    url('#{$fontsPath}BlissPro-Light.svg#BlissPro-Light')
      format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'CircularMedium';
  src: url('#{$fontsPath}CircularXXWeb-Medium.woff2') format('woff2'),
    url('#{$fontsPath}CircularXXWeb-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'CircularRegular';
  src: url('#{$fontsPath}CircularXXWeb-Regular.woff2') format('woff2'),
    url('#{$fontsPath}CircularXXWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
