.profile-dropdown {
  font-size: 1rem;
  text-align: left;
  height: 100%;

  @include desktop {
    display: inline-block;
    vertical-align: bottom;
  }

  @include desktop {
    // dont show backdrop on desktop
    &::before {
      content: none !important;
    }
  }

  &.show {
    .profile-dropdown__toggle {
      background: #fff;

      &:after {
        background-image: url('./../../../assets/icons/Icon_Grey_Chevron.svg');
        transform: rotate(-180deg);
      }

      &__circle {
        color: #fff;
        background-color: $purple;
        position: relative;

        > img {
          filter: none;
        }
      }
    }

    // show the dropdown menu when it's active
    .profile-dropdown__menu {
      height: 100%;
      height: calc(100vh - 108px);
      /* 58 + 50 */
      overflow-y: scroll;
      opacity: 1;
      transform: translateY(0);

      @include desktop {
        height: auto;
        overflow: hidden;
      }
    }
  }

  .profile-dropdown__toggle {
    background: transparent;
    outline: 0;
    border: 0;
    padding: 11px 2.286em 12px 1em;
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    height: 100%;
    font-family: 'CircularRegular', sans-serif;
    font-size: 0.875em;
    /* 14px */
    line-height: 1.929;
    position: relative;

    @include desktop {
      padding: 8px 2.286em 8px 1em;
    }

    &__circle {
      border-radius: 290486px;
      height: 27px;
      width: 27px;
      line-height: 27px;
      font-size: 11px;
      background: #fff;
      color: $purple;
      text-align: center;
      vertical-align: middle;
      padding: 0;
      display: inline-block;
      position: relative;

      > img {
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        display: block;
        left: 50%;
        filter: brightness(0) saturate(100%) invert(17%) sepia(34%)
          saturate(4892%) hue-rotate(252deg) brightness(83%)
          contrast(113%);
      }

      @include desktop {
        background: $purple;
        color: #fff;

        > img {
          filter: none;
        }
      }
    }

    &:after {
      content: ' ';
      position: absolute;
      right: 6px;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      background-image: url('./../../../assets/icons/Icon_White_Chevron.svg');
      background-size: 100%;
      transform: rotate(0deg);
      transition: all 0.2s ease-in-out;

      @include desktop {
        background-image: url('./../../../assets/icons/Icon_Grey_Chevron.svg');
      }
    }
  }

  div.profile-dropdown__menu {
    padding: 0;
    background-color: #fff;
    top: 55px;
    left: 0;
    width: 100%;
    height: 0;
    position: absolute;
    overflow: hidden;
    transition: height 0.5s ease;
    display: flex;
    flex-direction: column;

    @include desktop {
      top: 100%;
      z-index: 999; // sit below country dropdown toggle if open as well
      border-top: 0;
      padding: 0;
      right: 0;
      width: 285px;
      left: auto;
      box-shadow: 0 8px 8px rgba(30, 30, 30, 0.1);
    }

    .profile-dropdown__menu__item {
      font-family: 'CircularRegular', sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      text-decoration: none;
      letter-spacing: 0;
      color: $brand-navy;
      padding: 16px;
      border-top: 1px solid #d6d6d6;
      background: #fff;
      align-self: center;
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      width: 100%;
      margin: 0 auto;
      position: relative;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $cochlear-black;
      }

      &:last-child {
        margin-bottom: 100px;
      }

      @include desktop {
        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }
      }

      &:after {
        content: ' ';
        position: absolute;
        right: 16px;
        top: 50%;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        transform: translateY(-50%) rotate(0deg);
      }

      &--chevron {
        &:after {
          transform: rotate(-90deg);
          background-image: url('./../../../assets/icons/Icon_Purple_Chevron.svg');
          background-size: 100%;
        }
      }

      &--external {
        &:after {
          background-image: url('./../../../assets/icons/ext-link.svg');
          background-size: 21px auto;
        }
      }

      &--logout {
        text-align: left;
        border: 0 none;
        border-top: 1px solid #d6d6d6;
        background: #fff;

        &[disabled] {
          box-shadow: none;
          cursor: not-allowed;
        }
      }

      &--header {
        font-family: 'CircularRegular', sans-serif;
        color: $cool-grey;
        border-top: 0 none;
        cursor: default;

        &:after {
          content: none;
        }

        &:hover,
        &:focus {
          background: transparent;
          color: $cool-grey;
        }

        .subtitle {
          color: $brand-navy;
          font-family: 'CircularMedium', sans-serif;
          font-size: 18px;
          line-height: 22px;
          font-weight: 500;
          margin: 0;
          width: auto;
          padding: 3px 0;
        }

        .account {
          color: $brand-navy;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          margin: 0;
          padding: 3px 0;
        }
      }

      &--new-feature {
        border: 0 none;
        border-top: 1px solid #d6d6d6;
        background: rgba(78, 3, 192, 0.05);
        text-align: left;
        color: $link-colour;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 16px;

        &:hover,
        &:focus {
          color: $purple;
        }

        &:after {
          content: none;
        }
      }
    }
  }

  .popover {
    padding: 0;
    background-color: #4e03c0;
    top: 72px;
    right: 13px;
    position: absolute;
    box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    max-width: calc(100% - 26px);

    @include desktop {
      top: calc(100% + 15px);
      z-index: 998; // sit below profile dropdown toggle if open as well
      left: auto;
      right: 14px;
    }

    .popover__container {
      position: relative;
      padding: 14px 16px 16px 13px;
      line-height: 24px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      @include desktop {
        padding: 12px 11px 14px 12px;
      }

      // arrow up
      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: #4e03c0;
        transform: rotate(45deg);
        top: -7px;
        right: 23px;
      }
    }

    .button {
      font-family: 'CircularRegular', sans-serif;
      background: transparent;
      border: 0 none;
      color: #fff;
      padding: 0;
      vertical-align: middle;
      height: auto;

      &--popover {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        white-space: normal;
        display: block;

        @include desktop {
          font-size: 14px;
          line-height: 20px;
        }

        &:after {
          content: '';
          transform: rotate(-90deg);
          width: 20px;
          height: 20px;
          display: inline-block;
          background-image: url('./../../../assets/icons/Icon_White_Chevron.svg');
          background-repeat: no-repeat;
          background-size: 100% 100%;

          position: relative;
          margin: -5px 0 0 0;
          top: 5px;
          left: 0;
        }
      }

      &--dismiss {
        font-size: 12px;
        line-height: 22px;
        padding-left: 12px;
        margin: 2px 0 0 5px;
        position: relative;

        @include desktop {
          line-height: 18px;
          padding-left: 9px;
          margin: 2px 0 0 8px;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 1px;
          height: 32px;
          background-color: rgba(255, 255, 255, 0.3);
          top: 50%;
          transform: translateY(-50%);

          @include desktop {
            height: 22px;
          }
        }
      }

      // style hover/focus on both buttons
      &:hover,
      &:focus {
        outline: 0;
        text-decoration: underline;
      }
    }
  }
}

/* purple circle */
@include touch {
  .hero--default {
    .profile-dropdown {
      &__toggle {
        &__circle {
          color: #fff;
          background-color: $purple;
        }

        &:after {
          background-image: url('./../../../assets/icons/Icon_Grey_Chevron.svg');
        }
      }
    }
  }
}

// Logout Button with Spinner
// ---------------------------------------------
.profile-dropdown__menu__item--logout.has-spinner {
  &.is-submitting {
    &:after {
      content: none;
    }

    .lds-ring {
      position: absolute;
      height: 28px;
      width: 28px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
      right: 15px;
    }
  }
}
