.article__category__link {
  color: $purple;
  padding-left: 1.25rem;
  display: inline-block;

  &:hover,
  &:focus {
    color: $link-colour;
  }

  &--power-and-batteries {
    &::before {
      background: url('./../../../assets/icons/power_purple.svg')
        no-repeat center;
      background-size: 8px auto;
    }
  }

  &--sound {
    &::before {
      background: url('./../../../assets/icons/sound_purple.svg')
        no-repeat center 2px;
      background-size: 9px auto;
    }
  }

  &--usage-and-care {
    &::before {
      background: url('./../../../assets/icons/usage_purple.svg')
        no-repeat center 2px;
      background-size: 15px auto;
    }
  }

  &--apps-and-accessories {
    &::before {
      background: url('./../../../assets/icons/apps_purple.svg')
        no-repeat center;
      background-size: 14px auto;
    }
  }

  &--accessories {
    &::before {
      background: url('./../../../assets/icons/accessory_purple.svg')
        no-repeat center;
      background-size: 14px auto;
    }
  }
}
