$brand-main: #f1be48;
$brand-navy: #384967;
$eggplant: #3f1482;
$yellow-hover: #f4b013;
$spacing: 0.5rem;
$spacing3x: 3 * $spacing;
$spacing4x: 4 * $spacing;
$spacing6x: 6 * $spacing;
$spacing8x: 8 * $spacing;

$navHeight: 57px;

// Authorizer page
// --------------------------------------------
.error-message {
  &--authorizer {
    // buttons
    .sdw__button {
      min-width: 0;
      padding-left: $spacing6x;
      padding-right: $spacing8x;
      position: relative;
      font-family: 'CircularMedium';
      font-weight: 500;
      text-align: center;
      > span {
        min-width: 9.375rem; /* 150 / 16 */
        display: inline-block;
      }
      &:after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        left: auto;
        margin: 0;
        right: $spacing4x;
        transition: right 0.15s ease-in-out;
      }
      &:hover,
      &:focus {
        &:after {
          right: $spacing3x;
          left: auto;
        }
      }
      &-back {
        padding-left: $spacing8x;
        padding-right: $spacing6x;
        &:after {
          content: none;
        }
        &:before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(90deg);
          right: auto;
          margin: 0;
          left: $spacing4x;
          transition: left 0.15s ease-in-out;
        }
        &:hover,
        &:focus {
          &:before {
            left: $spacing3x;
          }
        }
      }

      &--cochlear-yellow {
        background: $brand-main;
        color: $eggplant;
        transition: background-color 0.15s ease-in-out;
        &:hover,
        &:focus {
          background: $yellow-hover;
        }
      }

      &--outline-purple {
        background: #fff;
        color: $eggplant;
        padding-left: $spacing6x + 0.0625rem;
        padding-right: $spacing8x + 0.0625rem;
        border: 1px solid $eggplant;
        &:after {
          background-image: url('./../../../assets/icons/Icon_Purple_Chevron.svg');
        }
        &:hover,
        &:focus {
          padding-top: 13px;
          padding-bottom: 14px;
          padding-left: $spacing6x;
          padding-right: $spacing8x;
          border: 2px solid $eggplant;
        }
        &.sdw__button-back {
          padding-left: $spacing8x + 0.0625rem;
          padding-right: $spacing6x + 0.0625rem;
          &:hover,
          &:focus {
            padding-left: $spacing8x;
            padding-right: $spacing6x;
          }
        }
      }
      &.has-spinner {
        &.is-submitting {
          padding-right: $spacing8x;
        }
        .lds-ring {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: $spacing4x;
          margin: 0 -5px 0 0;
        }
      }
    }

    // padding: 2rem 1rem 2.5rem;
    // display: flex;
    // flex-flow: column nowrap;
    // min-height: 500px;

    min-height: 100%; // allows content to grow larger than the screen height if needed
    position: absolute; // add position:absolute
    padding: calc(2rem + #{$navHeight}) 1rem 2.5rem;
    display: flex;
    flex-flow: column nowrap;
    margin-top: -$navHeight;

    @include desktop {
      position: static;
      max-width: 720px;
      min-height: 600px;
      margin: 0 auto;
      padding-top: 5rem;
    }
  }
  &__content {
    padding-bottom: 2rem;
    flex-grow: 1;
  }

  // margins and spacing with multiple
  &__button-control {
    display: flex;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
    }
    .sdw__button {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      @include desktop {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 2.5rem;
        margin-right: 2.5rem;
      }
      &:first-child {
        margin-top: 0;
        @include desktop {
          margin-left: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
        @include desktop {
          margin-right: 0;
        }
      }
    }
  }
}

.error-message {
  font-size: 1rem;
  &__image {
    max-width: 120px;
    margin-bottom: 1rem;
    img {
      display: block;
    }
    @include desktop {
      margin-bottom: 2rem;
    }
  }

  &__title {
    color: $brand-navy;
    font: 500 2.125em/1.176470588235294 'CircularMedium'; /* 34/16 ; 40/34 */
    margin-bottom: 0.5rem;
    @include desktop {
      margin-bottom: 1rem;
    }
  }

  &__text {
    color: $brand-navy;
    font: 400 1.125em/1.555555555555556 'CircularRegular'; /* 18/16 */ /* 28/18 */
    a {
      color: $eggplant;
      font-weight: 700;
    }
    &--code {
      margin-top: 2em;
    }
  }
}
