/*
.block { ... }
.block__element { ... }
.block--modifier { ... }
*/

// LAYOUT RELATED
// --------------------------------------------

.device-issue-article {
  // the article section inner wrapper
  .article {
    // the article including header and footer
    hr {
      margin: 1.5rem 0 0;
      @include desktop {
        margin: 2rem 0 0;
      }
    }
    .content {
      @include desktop {
        width: 87%;
      }
      ol {
        &.steps {
          margin: 0 0 0 2rem;
          list-style: none;
          counter-reset: li;
          > li {
            // margin: 0 0 0 1rem;
            margin: 2px 0 0 1rem; // 1px helps with anchor correction in firefox
            padding-top: 1.875rem;
            counter-increment: li;
            position: relative;
            ul {
              margin-top: 0;
              margin-left: 1rem;
            }
            li {
              margin-left: 0;
              padding-top: 0.5rem;
            }
          }
        }
      }
      // very rare that this is required.
      // some content dont have a list of steps
      > p {
        &:first-child {
          padding-top: 2rem;
        }
      }
      a {
        @extend .dont-break-out;
        &.external__link {
          &:after {
            background-size: 16px auto;
            padding-left: 1.25rem; /* 20px */
          }
        }
      }
    }
    .article__footer {
      border-top: 1px solid $grey-lighter;
      padding: 2rem;
      margin-top: 1rem;
      p {
        color: $dark;
      }
    }
  }
  .aside {
    // css solution to disable the sticky container used on tablet and up
    // @include mobile {
    //   margin-top:0;
    //   > div {
    //     > div {
    //       // overwrites of the plugin
    //       &:first-child {
    //         padding-bottom: 0 !important;
    //       }
    //       &.aside-inner {
    //         position: static !important;
    //         top: auto !important;
    //         left: auto !important;
    //         width: auto !important;
    //         transform: none !important;
    //       }
    //     }
    //   }
    // }
    margin-top: 3rem;
    .title {
      margin-bottom: 0.5rem;
    }
    .menu-list {
      margin-bottom: 2rem;
      a {
        font: 700 1rem/1.25 $font-header;
        color: $link-colour;
        padding: 0.5em 0;
        &:hover,
        &:focus {
          background-color: transparent;
          color: $link-hover;
        }
      }
    }
    .anchor-list {
      margin-left: 10px;
      list-style: none;
      counter-reset: li;
      li {
        margin-left: 5px;
        counter-increment: li;
        position: relative;
        &:before {
          content: counter(li) '.';
          left: -15px;
          top: 8px;
          position: absolute;
        }
      }
      a {
        color: $grey-dark;
        font-weight: 400;
        font-family: $font-primary;
      }
      .is-current {
        a {
          color: $link-colour;
          font-weight: 700;
          font-family: $font-header;
        }
        &:before {
          color: $link-colour;
          font-weight: 700;
          font-family: $font-header;
        }
      }
      .is-past {
        a {
          color: lighten($grey-dark, 25);
        }
        &:before {
          color: lighten($grey-dark, 25);
        }
      }
    }
  }
}

// Article Header Layout
// ---------------------------------------
.article__header {
  @include desktop {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__details {
    display: flex;
    flex-wrap: wrap; // wrap on a narrow device where date and group details cant fit on the one line
    align-items: flex-end;
    @include desktop {
      order: 1;
    }
    &__group {
      flex-shrink: 0; // group never wraps, it will shift onto the next line if it cant all fit
      margin-top: 0.25rem;
    }
  }
  &__title {
    @include desktop {
      flex-grow: 1;
      flex-basis: 100%;
    }
  }
  &__statistics {
    display: flex;
    align-items: center;
    @include desktop {
      order: 2;
      top: 2px;
      position: relative;
    }
  }
}

// APPEARANCE RELATED
// --------------------------------------------
.article__header {
  &__details {
    &__group {
      font: 400 0.875rem/1.4 $font-secondary;
    }
  }
  &__title {
    .title {
      margin: 1.25rem 0;
      font: 700 1.75rem/1.36 $font-header; /* 28/16 */
      @include desktop {
        padding-top: 20px;
        margin: 1rem 0 1rem;
        font: 700 2.125rem/1.36 $font-header; /* 34/16 */
      }
    }
  }
  &__statistics {
    .vertical-divider {
      width: 1px;
      height: 1.75rem; /* 28/16 */
      background: #efeeed;
      margin: 0 1.1875rem;
      @include desktop {
        margin: 0 1.875rem;
        top: 5px;
        position: relative;
      }
    }
  }
}

.device-issue-article {
  .card {
    @include mobile {
      box-shadow: none;
    }
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
    .card-content {
      @include mobile {
        padding: 0;
      }
    }
  }
  .article {
    .content {
      ol {
        &.steps {
          > li {
            font: 1rem/1.75 $font-secondary;
            color: $label-color;
            // purple circles
            &:before {
              left: -2.5rem;
              content: counter(li);
              background: $purple;
              border-radius: $radius-rounded;
              color: white;
              width: 1.5625rem;
              height: 1.5625rem;
              text-align: center;
              display: block;
              position: absolute;
              font: 700 1rem/1.5625 $font-header;

              //top: 2.125rem; // larger top positioning due to addition of buffer
            }
            h3 {
              font: 500 1.375rem/1.2 $font-header;
              color: $cochlear-black;
              margin-bottom: 0.25rem; /* 4/16 */
              //padding-top: 2rem; // extra space/buffer for use with anchor links
            }
            p + ul {
              margin-top: -1em;
            }
            a {
              // text-decoration: underline;
              &:hover,
              &:focus {
                color: $purple;
              }
            }
            .note {
              display: block;
              background-color: rgba(63, 20, 130, 0.07);
              font: italic 500 1rem/1.5 $font-secondary;
              color: $link-colour;
              padding: 1rem 1rem 1rem 5rem;
              margin: 1rem 0 0 -3rem;
              @extend .include-icon-before;
              @include tablet {
                padding: 1rem 3rem 1rem 5rem;
                margin: 1rem 0;
              }
              &:before {
                width: 2.375rem;
                height: 2.375rem;
                left: 1.6875rem; /* 27/16 */
                top: 1rem;
                @include tablet {
                  top: 50%; /* 24/16 */
                  margin-top: -1.1875rem;
                }
                background: url('./../../assets/icons/note.svg')
                  no-repeat center;
                background-size: 38px auto;
              }
            }
          }
        }
      }
    }
  }
}
