$modal-z: 2147483650 !default;
$modal-background-background-color: rgba(255, 255, 255, 0.95);

$modal-content-width: 700px;
$modal-content-margin-mobile: auto;
$modal-content-spacing-mobile: 160px;
$modal-content-spacing-tablet: 40px;

$modal-close-dimensions: 40px;
$modal-close-right: 20px;
$modal-close-top: 20px;

html {
  &.is-clipped {
    overflow: hidden !important;
  }
}

$offset: 0;

.overlay {
  bottom: $offset;
  left: $offset;
  position: absolute;
  right: $offset;
  top: $offset;
}

/* -----------------------------
Basic setup for modal
----------------------------- */
.nf-modal {
  @extend .overlay;

  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: $modal-z;

  // Modifiers
  &.is-active {
    display: flex;
  }

  &__background {
    @extend .overlay;

    background-color: $modal-background-background-color;
  }

  &__content {
    margin: 0 $modal-content-margin-mobile;
    max-height: calc(100vh - #{$modal-content-spacing-mobile});
    position: relative;
    width: 96%;
    box-shadow: 0 2px 77px 0 rgba(0, 0, 0, 0.13);

    @include tablet {
      width: $modal-content-width;
      max-height: calc(100vh - #{$modal-content-spacing-tablet});
      margin: 0 auto;
    }
  }
}

/* -----------------------------
Modal customisations
----------------------------- */
.nf-modal {
  &__content {
    text-align: center;
    border-radius: 10px;
    background: #fff;
  }

  &__close-btn--text {
    border: 0 none;
    background: transparent;
    color: rgb(73, 26, 139);
    font-size: 16px;
    font-family: $font-primary;
    font-weight: 500;
    margin: 0 auto 25px;
    position: relative;

    @include tablet {
      margin: 0 auto 35px;
    }

    .icon--delete {
      width: 12px;
      height: 12px;
      display: inline-block;
      background-image: url('./../../../assets/icons/Icon_Purple_Close.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-right: 8px;
      position: relative;
      top: 1px;
    }
  }

  &__close-btn {
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    border-radius: 290486px;
    cursor: pointer;
    pointer-events: auto;
    display: block;
    font-size: 0;
    position: absolute;
    top: -45px;
    right: 0;
    z-index: 1;
    padding: 0;

    &::before,
    &::after {
      background-color: #8b8d8e;
      content: '';
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform-origin: center center;
    }

    &::before {
      width: 60%;
      height: 3px;
    }

    &::after {
      width: 3px;
      height: 60%;
    }

    &:hover,
    &:focus {
      background-color: rgba(10, 10, 10, 0.3);

      &::before,
      &::after {
        background-color: #fff;
      }
    }

    @include desktop {
      right: -43px;
    }
  }

  /* -----------------------------
  Slideshow
  ----------------------------- */

  .slick-slider {
    padding: 2em 1em;

    @include tablet {
      padding: 2em 1em 1em;
    }

    @media only screen and (max-height: 42em) {
      .slick-list {
        max-height: 24em;
        max-height: calc(100vh - 282px);
        overflow-y: scroll;
      }
    }

    ul {
      &.slick-dots {
        bottom: -85px;
      }
    }

    .slick-arrow {
      display: none !important;
    }
  }

  /* --------------------------
  New feature slides
  -----------------------------  */

  .slide {
    height: 100%;

    &__figure {
      display: block;
      position: relative;
      width: 140px;
      height: 140px;
      margin: 0 auto;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__title {
      color: rgb(30, 30, 30);
      font-size: 32px;
      font-family: $font-header;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0;
      line-height: 40px;
      margin: 0 auto 12px;
      max-width: 480px;
    }

    &__text {
      color: rgb(85, 86, 90);
      font-family: $font-primary;
      font-size: 18px;
      letter-spacing: -0.23px;
      line-height: 30px;
      text-align: center;
      margin: 0 auto 1.5em;

      @include tablet {
        max-width: 432px;
      }
    }

    &__button {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      border: 0 none;
      border-radius: 290486px;
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      font-family: $font-header;
      font-size: 1.125rem;
      font-stretch: normal;
      font-style: normal;
      font-weight: 700;
      line-height: 1.166666666666667;
      padding: 14px 25px 15px 30px;
      text-align: center;
      user-select: none;
      white-space: nowrap;
      cursor: pointer;
      text-decoration: none;
      transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
      min-width: 246px;
      outline: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      &.no-icon {
        padding: 14px 25px 15px;

        &::after {
          content: none;
        }
      }

      @include mobile {
        display: block;
        width: 100%;
        left: 0;
        min-width: none;
        transform: none;
      }

      &::after {
        transition: left 0.15s ease-in-out;
        content: '';
        transform: rotate(-90deg);
        width: 24px;
        height: 24px;
        position: relative;
        display: inline-block;
        margin: -7px 0 0;
        top: 6px;
        left: 0;
        background-image: url('./../../../assets/icons/Icon_White_Chevron.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      &:hover,
      &:focus {
        &::after {
          left: 6px;
        }
      }

      &--primary {
        background-color: #fdc82f;
        border-color: #fdc82f;
        color: #1e1e1e;

        &:hover,
        &:focus {
          background-color: #ffb000;
        }

        &::after {
          background-image: url('./../../../assets/icons/Icon_Purple_Chevron.svg');
        }
      }
    }
  }
}