.modal-background {
  background: rgba(255, 255, 255, 0.95);
}

// on mobile position it in relation to the card upper right corner
// on desktop positioned to the windows upper right corner
@include touch {
  .modal-card-head,
  .modal-card-foot {
    background: transparent;
    border: 0 none;
    border-radius: 0;
  }

  .modal-card-head {
    padding: 0;
    position: relative;
    height: 40px;
  }

  .modal-card-foot {
    position: relative;
    border-top: 1px solid rgba($link-colour, 0.25);
    background-color: #fff;
    height: 60px;
  }
}

.modal-card-head,
.modal-card-foot {
  width: 100%;

  @include tablet {
    max-width: 700px;
  }
}

@include desktop {
  .modal-card-head {
    background: transparent;
    border: 0 none;
    border-radius: 0;
  }

  .modal-card-foot {
    display: none;
  }
}

.modal-close {
  position: absolute;
  top: -4px;
  right: 4px;
  z-index: 1;
  padding: 0;

  &::before,
  &::after {
    background-color: #8b8d8e;
  }

  &::before {
    width: 60%;
    height: 3px;
  }

  &::after {
    width: 3px;
    height: 60%;
  }

  &:hover,
  &:focus {
    &::before,
    &::after {
      background-color: #fff;
    }
  }

  @include desktop {
    position: absolute;
    right: -34px;
  }
}

// fixed close footer button
.modal-card-foot {
  @include tablet {
    margin-top: -4rem;
  }

  .close {
    font: 700 1.125rem/1.75 $font-header;
    text-align: center;
    color: #3f1482;
    margin: 0 auto;
    vertical-align: middle;

    .delete {
      background: transparent;

      &::before,
      &::after {
        background: $link-colour;
      }
    }
  }
}

.modal-card {
  box-shadow: 0 2px 77px 0 rgba(0, 0, 0, 0.13);
  background-color: #fff;
  text-align: center;
  width: 100%;

  @include tablet {
    max-width: 700px;
    margin-bottom: 4rem;
  }

  .modal-card-body {
    padding-bottom: 40px;

    &__image {
      width: 100px;
      margin: 0 auto;
      padding: 1rem 0;

      @include tablet {
        width: auto;

        img {
          max-height: 100px;
          height: auto; // overwrite img height and width if set
          width: auto; // overwrite img height and width if set
        }
      }
    }

    .modal-card-title {
      font: 700 1.375rem/1.25 $font-header;
      margin-bottom: 1rem;

      @include tablet {
        font: 700 2rem/1.25 $font-header;
        margin: 0 auto 1rem;

        // we want to keep help these titles wrap onto two lines
        .modal--lend-a-hand &,
        .modal--cant-see-my-device &,
        .modal--cant-identify-my-device &,
        .modal--device-out-of-warranty &,
        .modal--device-already-submitted &,
        .modal--device-not-supported & {
          width: 75%;
        }
      }
    }

    .modal-card-subtitle {
      font: 1rem/1.63 $font-secondary;
      color: $label-color;
      margin-bottom: 1rem;

      @include tablet {
        width: 80%;
        margin: 0 auto 1rem;

        //  we want to keep this one full width
        .modal--thumbs-up & {
          width: 100%;
        }
      }

      a {
        font: 1rem/1.63 $font-secondary;
      }
    }
  }

  .form__controls {
    margin-top: 2rem;
    position: relative;
    text-align: center;

    .close {
      position: absolute;
      left: 0;
      font: 700 1rem/2 $font-header;
      color: $grey-dark;
      margin-top: -1rem;
      top: 50%;

      .delete {
        margin-right: 0;
        background-color: transparent;
        border-radius: 0;

        &::before,
        &::after {
          background-color: $grey-dark;
        }
      }
    }

    .sdw__button {
      margin: 0 auto;
    }
  }
}

.modal {
  .sdw__button--purple {
    &::after {
      content: none;
    }
  }

  &--thumbs-up {
    .modal-card {
      .modal-card-title {
        color: $aa-green;
      }
    }

    .modal-card-body {
      padding-bottom: 2rem;
    }
  }

  &--success {
    .modal-card {
      .modal-card-title {
        margin-top: 1rem;
      }

      .sdw__button {
        margin-top: 2rem;
      }
    }
  }

  &--thumbs-down {
    .modal-card {
      .modal-card-title {
        color: $aa-red;
      }
    }
  }

  &--support-options {
    .modal-card-body {
      padding-bottom: 0.5rem;
    }

    .content {
      > ul {
        margin: 0 -20px;
        list-style: none outside none;
        text-align: left;
        border-left: solid 3px $yellow-highlight;

        li {
          border-top: solid 1px #efeeed;
          position: relative;

          a {
            padding: 1.25rem 3rem 1.25rem 5.5rem;
            color: $cochlear-black;
            font: 700 1.125rem/1.2 $font-header;
            display: block;

            > .phone {
              color: $purple;
              white-space: nowrap;
            }

            &.include-icon-before {
              &::before {
                width: 1.625rem;
                height: 1.625rem;
                left: 42px;
              }
            }

            &.include-icon-after {
              &::after {
                width: 1.625rem;
                height: 1.625rem;
                right: 20px;
              }
            }

            &.icon-call {
              &::before {
                background: url(./../../assets/icons/call.svg)
                  no-repeat center;
                background-size: 23px auto;
              }
            }

            &.icon-chat {
              &::before {
                background: url(./../../assets/icons/chat.svg)
                  no-repeat center;
                background-size: 26px auto;
              }
            }

            &.icon-service {
              &::before {
                background: url(./../../assets/icons/submit-service-r.svg)
                  no-repeat center;
                background-size: 26px auto;
              }
            }

            &.icon-enquiry {
              &::before {
                background: url(./../../assets/icons/enquiry.svg)
                  no-repeat center;
                background-size: 25px auto;
              }
            }

            &.icon-upgrade {
              &::before {
                background: url(./../../assets/icons/upgrade.svg)
                  no-repeat center;
                background-size: 18px auto;
              }
            }

            &.icon-user-guides {
              &::before {
                background: url(./../../assets/icons/user-guides.svg)
                  no-repeat center;
                background-size: 20px auto;
              }
            }

            &.icon-link {
              &::after {
                background: url(./../../assets/icons/Icon_Purple_Chevron.svg)
                  no-repeat center;
                background-size: 22px auto;
                transform: rotate(-90deg);
              }
            }

            &.icon-external {
              &::after {
                background: url(./../../assets/icons/ext-link.svg)
                  no-repeat center;
                background-size: 16px auto;
              }
            }

            &.is-offline {
              &.icon-call {
                &::before {
                  background-image: url(./../../assets/icons/call_disabled.svg);
                }
              }

              &.icon-chat {
                &::before {
                  background-image: url(./../../assets/icons/chat_disabled.svg);
                }
              }
            }

            &.is-coming-soon {
              &.icon-service {
                &::before {
                  background-image: url(./../../assets/icons/inact-device-repair.svg);
                }
              }

              &.icon-link {
                &::after {
                  content: none;
                }
              }

              @include tablet {
                .availability-status {
                  right: 20px;
                  left: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
