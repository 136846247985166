.error__screen {
  .error__screen__intro {
    @include desktop {
      max-width: 600px;
    }

    &__image {
      width: 93px;
      padding-bottom: 2rem;
      margin-top: 2rem;

      @include desktop {
        width: 141px;
        margin-top: 4rem;
      }
    }

    .title {
      font-size: 2rem;
      color: $cochlear-black;
    }
  }

  .content {
    max-width: 657px;
    margin-top: 2rem;

    >ul {
      margin: 0;
      list-style: none outside none;
      text-align: left;

      li {
        border-top: solid 1px #efeeed;
        position: relative;

        a {
          padding: 1.25rem 2rem 1.25rem 4.125rem;
          color: $cochlear-black;
          font: 700 1.125rem/1.2 $font-header;
          display: block;

          >.phone {
            color: $purple;
            white-space: nowrap;
          }

          &.include-icon-before {
            &::before {
              width: 1.625rem;
              height: 1.625rem;
              left: 22px;
            }
          }

          &.include-icon-after {
            &::after {
              width: 1.625rem;
              height: 1.625rem;
              right: 4px;
            }
          }

          &.icon-call {
            &::before {
              background: url(./../../../assets/icons/call.svg) no-repeat center;
              background-size: 23px auto;
            }
          }

          &.icon-chat {
            &::before {
              background: url(./../../../assets/icons/chat.svg) no-repeat center;
              background-size: 25px auto;
            }
          }

          &.icon-enquiry {
            &::before {
              background: url(./../../../assets/icons/enquiry.svg) no-repeat center;
              background-size: 25px auto;
            }
          }

          &.icon-upgrade {
            &::before {
              background: url(./../../../assets/icons/upgrade.svg) no-repeat center;
              background-size: 18px auto;
            }
          }

          &.icon-external {
            &::after {
              background: url(./../../../assets/icons/ext-link.svg) no-repeat center;
              background-size: 16px auto;
            }
          }

          &.is-offline {
            &.icon-call {
              &::before {
                background-image: url(./../../../assets/icons/call_disabled.svg);
              }
            }

            &.icon-chat {
              &::before {
                background-image: url(./../../../assets/icons/chat_disabled.svg);
              }
            }
          }
        }
      }
    }
  }
}