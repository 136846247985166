#embedded-messaging.embedded-messaging {
  @media only screen and (max-width: 63.99em) {
    .embeddedMessagingConversationButtonWrapper {
      .embeddedMessagingConversationButton {
        // intro button
        // needs to clear mobile main navbar
        bottom: 5.3125em; // 60 + 25px;
      }
    }
    // adjustments because we are raising by 60px
    // open chat in minimised state
    > .embeddedMessagingFrame[class~='isMinimized'] {
      bottom: 5.3125em;
    }
    // adjustments because we are raising by 60px
    // open chat in maximised state
    > .embeddedMessagingFrame[class~='isMaximized'] {
      bottom: 10em;
    }
  }

  // overwrite existing css to raise bottom of windowns and buttons
  // due to cochlear mobile nav
  @media only screen and (min-width: 15em) and (max-width: 47.9375em) {
    // covers the mobile header if required
    > .embeddedMessagingFrame[class~='isMaximized'],
    > .embeddedMessagingConversationButtonWrapper {
      z-index: 2147483640;
    }

    > .embeddedMessagingConversationButtonWrapper {
      .embeddedMessagingLoadingSpinner {
        padding-inline-start: 0; // Remove the left padding so that the spinner is centered
      }
    }

    // mobile sized desktop screen / ipad
    > .embeddedMessagingFrame[class~='isMaximized'] {
      bottom: 9em;
      max-height: calc(100vh - 16em);
    }
  }
}

// mobile issue where website is disabled whilst chat is open
@media only screen and (min-width: 15em) and (max-width: 47.9375em) {
  #root[inert] ~ #embedded-messaging.embedded-messaging {
    > .embeddedMessagingFrame[class~='isMaximized'] {
      bottom: 0;
      // used % instead of vh.
      // chat always displays at the top of the page, forcing the mobile url bar to also show (most mobile browsers hide the url as you scroll)
      // Using vh will size the element as if the URL bar is always hidden while using % will size the element as if the URL bar were always showing
      max-height: calc(100% - 2em);
    }
  }
}
