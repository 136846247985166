// new chat feature
.site {
  // dont show initially. Wait until online agent is confirmed
  .embeddedServiceSidebar,
  .embeddedServiceHelpButton {
    // display: none;

    // &.online {
    //     display: block;
    // }

    // shape and position
    .helpButton {
      // z-index: 29;
      /* Nav is 30 */

      bottom: 65px;

      @include desktop {
        bottom: 25px;
      }

      border-radius: 23px;

      .uiButton {
        border-radius: 23px;

        &:hover,
        &:focus {
          &:before {
            border-radius: 23px;
          }
        }
      }
    }
  }

  .embeddedServiceSidebar,
  .embeddedServiceHelpButton {
    .helpButton {
      background-color: #3f1482;
      font-family: 'CircularRegular', sans-serif;
      border: 0 none;

      .uiButton {
        background-color: #3f1482;
        font-family: 'CircularRegular', sans-serif;
        border: 0 none;

        &:focus {
          outline: 1px solid #3f1482;
        }
      }
    }

    .message {
      background: transparent;
    }
  }

  ul.slds-carousel__indicators {
    display: flex;
    margin: 0.5rem 0px;
  }
  li.slds-carousel__indicator {
    display: flex;
    margin: 0px 0.25rem;
  }
  .agent.chat-content[CLWCP-lwcchatpack_lwcchatpack] {
    color: #384967;
    background: #e1d5f4;
    border-radius: 10px 10px 10px 0;
    float: left;
  }
  .chasitor.chat-content[CLWCP-lwcchatpack_lwcchatpack] {
    background: #3f1482;
    color: #fff;
    margin-left: auto;
  }
}
