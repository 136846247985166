.hero-body {
  text-align: left;
  padding: 2rem 0.75rem 3rem;

  @include desktop {
    padding: 2rem 1.5rem 3rem;
    text-align: center;
  }
}

.hero--home {
  .hero-body {
    &--slide {
      position: relative;
      padding: 0;

      &--control {
        &--spacer {
          width: 100%;
          height: auto;
          margin: 0 auto;
        }

        &--background-image {
          position: relative;
          min-height: 480px;
          max-height: 860px;
          /* common background image props */
          background-repeat: no-repeat;
          background-position: center top;
          background-size: 100% auto;

          /* toggle on and off */
          &.desktop-image,
          &.widescreen-image,
          &.fullhd-image {
            display: none;
          }

          @include tablet {
          }

          @include desktop {
            min-height: 0;
            max-height: 700px;
            background-position: center center;

            &.desktop-image {
              display: block;
            }

            &.mobile-image {
              display: none;
            }
          }

          @include widescreen {
            &.desktop-image {
              display: none;
            }

            &.widescreen-image {
              display: block;
            }
          }

          @include fullhd {
            &.widescreen-image {
              display: none;
            }

            &.fullhd-image {
              display: block;
            }
          }
        }
      }

      &--content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @include tablet {
          align-items: center;
        }

        @include desktop {
          left: 50%;
          max-width: 1180px !important;
          transform: translateX(-50%);
        }
      }

      &--content {
        min-height: 200px;
        width: 100%;
        text-align: left;
        flex: 0 1 auto;
        padding: 0 1rem;
        margin-top: 5rem;

        @include tablet {
          margin-top: -1.25rem;
          padding: 0 2.5rem;
          min-height: 330px;
        }

        @include desktop {
          width: 100%;
          margin-top: -2.5rem;
          padding: 0 6rem;
        }
      }
    }

    // Hero content
    .title,
    .subtitle {
      color: #fff;
    }

    /* 36 / 42 */
    .title {
      font-size: 36px;
      font-size: 11.25vm;
      font-size: 11.25vmin;
      line-height: 1.166666666666667;
      margin-bottom: 0.5rem;

      @include tablet {
        font-size: 3rem;
        line-height: 1.354166666666667;
      }
    }

    /* 18 / 28 */
    .subtitle {
      font-size: 18px;
      font-size: 5.625vm;
      font-size: 5.625vmin;
      line-height: 1.555555555555556;
      font-weight: 400;
      margin-bottom: 0.75rem;
      width: 96%;

      @include tablet {
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 1.58;
        max-width: 534px;
        margin-bottom: 1.5rem;
      }
    }
  }

  // Slider
  // ---------------------------
  .slick-slider {
    margin-top: -58px;

    @include desktop {
      margin-top: -70px;
    }

    .hero-body {
      margin-top: 0;
    }

    .slick-slide {
      padding-bottom: 0;
    }
  }

  // Slider arrows
  // --------------------------

  .slick-arrow {
    position: absolute;
    z-index: 1;
    display: none !important;
    background: none;
    border: 0;
    font-size: 0;
    line-height: 0;
    transform: translateY(-50%);

    @include desktop {
      display: block !important;
    }
  }

  .slick-prev {
    top: calc(50% - 3.25rem);
    left: 8px;

    // for fullhd we want:
    // Chevron Centred between text and edge (point of cheveron 44px from edge on both sides)
    @include fullhd {
      left: calc(50% - 684px);
    }

    &:before {
      content: '';
      background: url('./../../assets/icons/Icon_White_Chevron.svg')
        no-repeat center;
      background-size: 100% auto;
      width: 4rem;
      height: 4rem;
      display: block;
      transform: rotate(90deg);
    }
  }

  .slick-next {
    top: calc(50% - 3.25rem);
    right: 8px;

    @include fullhd {
      right: calc(50% - 684px);
    }

    &:before {
      content: '';
      background: url('./../../assets/icons/Icon_White_Chevron.svg')
        no-repeat center;
      background-size: 100% auto;
      width: 4rem;
      height: 4rem;
      display: block;
      transform: rotate(-90deg);
    }
  }

  // Slider dots
  // --------------------------

  .slick-slider ul.slick-dots {
    z-index: 1;
    bottom: 4rem;

    @include desktop {
      bottom: 30%;
    }

    li {
      @include desktop {
        height: 20px;
        width: 22px;
      }
    }

    button {
      @include desktop {
        &:before {
          margin-left: -8px;
          width: 16px;
          height: 16px;
          border-radius: 8px;
        }
      }
    }
  }
}

.hero-links {
  .button {
    background-color: rgba(255, 255, 255, 0.25);
    border-color: transparent;
    color: #fff;
    font: 700 1rem/1.2 $font-header;
    padding-left: 40px;
    height: auto;
    margin-top: -1rem;

    @include touch {
      font-size: 14px;
      padding-top: 3px;
      padding-bottom: 3px;
      margin-bottom: 2rem;
    }

    > span {
      &:first-child {
        position: relative;
        top: -1px;
      }
    }

    &:before {
      background: url('./../../assets/icons/Icon_White_Arrow.svg')
        no-repeat center;
      background-size: 12px auto;
      left: 10px;
      top: calc(50% - 10px);
    }

    &.on-white {
      background-color: #f1eef6;
      color: $link-colour;

      &:before {
        background-image: url('./../../assets/icons/Icon_Purple_Arrow.svg');
      }
    }
  }
}

.hero--devices,
.hero--category {
  background: $purple;

  @include touch {
    .hero-body {
      min-height: 300px;
      padding-right: 90px;
      padding-top: 40px;
    }
  }

  .title {
    color: white;
    margin-bottom: 1rem;
    line-height: 1.25;

    @include tablet {
      &.is-2 {
        margin-bottom: 0;
        font-size: 2.75rem; // 44px
        line-height: normal;
      }
    }

    .highlight {
      color: $cochlear-yellow;
    }
  }

  .dropdown.hero--dropdown {
    .button {
      padding: 0 2.25rem 0 0;
      border: 0 none;
      height: auto;
      color: $yellow-highlight;
      text-decoration: none;
      background: transparent;
      white-space: nowrap;
      position: relative;
      margin-bottom: 0;

      @include tablet {
        padding: 0 3.375rem 0 0;
      }

      &:hover,
      &:focus {
        background: transparent;
      }

      &:focus {
        box-shadow: none;

        &:before {
          content: '';
          position: absolute;
          height: 4px;
          background: $yellow-highlight;
          bottom: -5px;
          left: 0;
          right: 12px;

          @include tablet {
            bottom: -5px;
          }
        }

        // &:not(:active) {
        // }
      }
    }

    &.is-active {
      .dropdown-menu {
        visibility: visible;
      }
    }

    .dropdown-menu {
      visibility: hidden;
      display: block;
      top: 40px;

      @include tablet {
        top: 53px;
      }

      box-shadow: 8px 2px 24px 0 rgba(0, 0, 0, 0.09);

      .dropdown-content {
        border-radius: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        a {
          &.dropdown-item {
            font-size: 1rem;
            color: $link-colour;
            padding: 1rem 3.5rem 1rem 3.75rem;
            border-left: 4px solid transparent;
            line-height: 1.25rem;
            border-top: 1px solid #fff;

            // position: relative;
            &:hover,
            &:focus,
            &.is-active {
              background-color: rgba(239, 238, 237, 0.5);
              border-left: 4px solid $yellow-highlight;
            }

            span {
              display: block;
              color: $purple;
              font: 400 0.75rem/1.2 $font-secondary;
            }

            &:before,
            &:after {
              width: 2rem;
              height: 2rem;
              top: 50%;
              margin-top: -16px; // based on 32x32 image dimension on the block;
            }

            &:before {
              left: 20px;
            }

            // chevron
            &:after {
              background: url('./../../assets/icons/Icon_Purple_Chevron.svg')
                no-repeat center;
              background-size: 22px auto;
              transform: rotate(-90deg);
              right: 18px;
              top: 0;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  .subtitle {
    color: white;
    font-weight: 400;

    @include mobile {
      font-size: 1rem;
    }

    @include desktop {
      width: 60%;
      margin: 1rem auto 0;
      line-height: 1.55;
    }

    @include widescreen {
      width: 50%;
    }

    .highlight {
      color: $cochlear-yellow;
    }
  }

  .icon-chevron {
    &:after {
      background: url('./../../assets/icons/Icon_Yellow_Chevron.svg')
        no-repeat 0 0;
      background-size: 36px auto;
      width: 2.25rem;
      height: 2.25rem;
      top: calc(50% - 18px);

      @include tablet {
        background-size: 54px auto;
        width: 3.375rem;
        height: 3.375rem;
        top: calc(50% - 27px);
      }
    }
  }
}

.hero {
  .hero-head {
    @include touch {
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
    }
  }

  &--default {
    .hero-head {
      background: #fff;
    }

    .brandmark {
      .reversed {
        display: none;
      }
    }

    .wordmark {
      .reversed {
        display: none;
      }
    }
  }

  &--home,
  &--devices {
    .brandmark {
      .full-colour {
        display: none;
      }

      .reversed {
        display: block;
      }
    }

    .wordmark {
      .black {
        display: none;
      }

      .reversed {
        display: block;
      }
    }

    .message--end-of-support {
      line-height: 1.4;
    }

    .link {
      color: $primary;
    }
  }

  &--category {
    figure {
      &.image {
        @include desktop {
          margin: 0 auto;
        }

        img {
          width: auto;
        }
      }
    }

    .brandmark {
      .full-colour {
        display: none;
      }
    }

    .wordmark {
      .black {
        display: none;
      }
    }

    &--usage-and-care {
      background: #00857c;

      .hero-head {
        background: #008770;

        @include desktop {
          background: #00857c;
        }
      }
    }

    &--apps-and-accessories,
    &--accessories {
      background: #740053;

      .hero-head {
        background: #772059;

        @include desktop {
          background: #740053;
        }
      }
    }

    &--sound {
      background: #005e87;

      .hero-head {
        background: #005c84;

        @include desktop {
          background: #005e87;
        }
      }
    }

    &--power-and-batteries {
      background: #782323;

      .hero-head {
        background: #70382d;

        @include desktop {
          background: #782323;
        }
      }
    }

    // dropdown nav link icons
    .dropdown-menu {
      min-width: 260px;

      .dropdown-content {
        a {
          &.dropdown-item {
            &.apps-and-accessories {
              &:before {
                background: url('./../../assets/icons/apps_purple.svg')
                  no-repeat center;
                background-size: 26px auto;
              }
            }

            &.accessories {
              &:before {
                background: url('./../../assets/icons/accessory_purple.svg')
                  no-repeat center;
                background-size: 26px auto;
              }
            }

            &.power-and-batteries {
              &:before {
                background: url('./../../assets/icons/power_purple.svg')
                  no-repeat center;
                background-size: 16px auto;
              }
            }

            &.sound {
              &:before {
                background: url('./../../assets/icons/sound_purple.svg')
                  no-repeat center;
                background-size: 18px auto;
              }
            }

            &.usage-and-care {
              &:before {
                background: url('./../../assets/icons/usage_purple.svg')
                  no-repeat center;
                background-size: 29px auto;
              }
            }
          }
        }
      }
    }
  }
}

.hero {
  padding-top: calc(#{$nav-height} + #{$nav-border-height});

  @include desktop {
    padding-top: calc(#{$nav-desktop-height} + 45px + 5px);
  }

  &.hero--service-requests {
    box-shadow: none;

    @include desktop {
      position: relative;

      &:before {
        content: '';
        background: url('./../../assets/backgrounds/bg.png') no-repeat
          right top;
        position: absolute;
        width: 100%;
        height: 474px;
        top: 0;
        right: 0;
        transform: scaleY(-1);
      }
    }

    .hero-head {
      .navbar {
        border-bottom: 0;
      }
    }

    .hero-body {
      text-align: left;

      .hero-links {
        margin-bottom: 1rem;

        .button {
          @include desktop {
            font-size: 0.875rem;
          }
        }
      }
    }

    .subtitle {
      font: 400 1.125rem/1.67 $font-primary;
      font-size: 1.125rem !important;
      color: $grey-dark;
      max-width: 756px;
    }
  }
}
