.availability-status {
  &::before {
    content: '';
    display: inline-block;
    margin-left: -18px;
    margin-right: 9px;
    width: 10px;
    height: 10px;
    border-radius: $radius-rounded;
  }

  &--has-label {
    font: 400 1rem/1.2 $font-primary;
  }

  &--is-offline {
    color: $aa-red;

    &::before {
      background-color: $aa-red;
    }
  }

  &--is-online {
    color: $aa-green;

    &::before {
      background-color: $aa-green;
    }   
  }
}