.article__device__link {
  color: #00857c;
  text-transform: capitalize;
  margin-right: 0.25rem;
  display: inline-block;

  &:hover,
  &:focus {
    color: darken(#00857c, 5%);
  }
}
