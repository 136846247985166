/*
.block { ... }
.block__element { ... }
.block--modifier { ... }
*/

/* ------------------
Component specific style
------------------- */
.devices-listing {
  transform: translate3d(0, 0, 0);
  // perspective on the hover items can make it appear above the modal when active
  .column {
    padding-top: 5px;
    padding-bottom: 5px;
    > a {
      display: block;
      height: 100%;
    }
    &:last-child {
      .title {
        line-height: 1.1; // target: `My sound processor is not here`
      }
    }
  }
}

.device {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  box-sizing: border-box;
  border: solid 1px #ded9e4;
  &__family {
    border: 0;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding: 0.5rem 0.8125rem 0.75rem 0.75rem; /* 8px 13px 12px 12px */
    @include desktop {
      padding: 0.75rem 0.25rem 0.75rem 0.5rem;
    }
    @include widescreen {
      padding: 0.9375rem 0.5rem 1.25rem 0.75rem; /* 15px 8px 20px 12px */
    }
  }

  &__image {
    width: 45px;
    height: 45px;
    flex: 0 0 45px;
    @include widescreen {
      width: 65px;
      height: 65px;
      flex: 0 0 65px;
    }
  }
  &__content {
    flex: 0 1 auto;
    text-align: left;
    margin-left: 0.5rem;
    // flex-grow: 2;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include desktop {
      align-content: center;
    }
    .title {
      margin-bottom: 0.5rem;
      font-weight: 700;
      color: $purple;
      line-height: 1.67;
      margin: 0;
      flex-grow: 2;
      flex-basis: 100%;
      position: relative;
      &:after {
        content: '';
        transform: rotate(-90deg);
        width: 24px;
        height: 24px;
        position: absolute;
        display: inline-block;
        right: -7px;
        background-image: url('./../../assets/icons/Icon_Purple_Bright_Chevron.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      @include desktop {
        &.is-5 {
          font-size: 1.25rem; /* 20/16 */
          line-height: 1.25;
          flex-grow: 0;
          &:after {
            content: none;
          }
        }
      }
    }
    .device__subtitle,
    .device__details {
      flex-basis: 50%;
      flex-grow: 1;
      font: 400 0.875rem/1.1 $font-header;
      @include widescreen {
        font-size: 1rem;
      }
    }
    .device__subtitle {
      color: $purple;

      @include desktop {
        margin-top: -2px;
        font-size: 16px;
      }
    }
    .device__details {
      color: $grey-dark;
      text-align: right;
      @include desktop {
        display: none;
      }
    }
  }
  > .device__link {
    width: 100%;
    margin: 0;
  }

  &--help {
    .device__family {
      display: flex;
      align-items: center;
    }
    @include desktop {
      .device__family {
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
      }
      .device__content {
        margin-top: 1.25rem;
        padding-right: 4.5rem;
      }
    }
    @include widescreen {
      .device__content {
        padding-right: 6rem;
      }
    }

    .title {
      &:after {
        content: none;
      }
    }
  }
  &--accordion {
    padding: 0;
    //  flex-direction: column;
    > button {
      // display: flex;
      // flex-flow: row nowrap;
      background: #fff;
      width: 100%;
      @include desktop {
        align-items: center;
      }
    }
    .title {
      &:after {
        transition: transform 0.2s ease-in-out;
        transform: rotate(0deg);
      }
    }
    .device__details {
      color: $purple;
    }

    .device__slim {
      display: none;
      @include desktop {
        display: block;
      }
    }
    @include touch {
      &.is-active {
        border-top: 3px solid $yellow;
        > button {
          padding-top: 0.5625rem;
          // @include desktop {
          //   padding-top: 0.6875rem;
          // }
          // @include widescreen {
          //   padding-top: 1rem;
          // }
        }
        .device__slim {
          display: block;
        }
        .title {
          &:after {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  .device__slim {
    display: none;
    @include desktop {
      display: block;
    }
  }

  .device__slim {
    // display: block;
    padding: 1rem 0.5rem;
    border: 0;
    border-top: 1px solid #ded9e4;
    @include desktop {
      padding: 1rem;
      border-left: 3px solid $yellow;
    }
    &:hover,
    &:focus {
      .device__subtitle {
        &:after {
          right: -13px;
        }
      }
    }

    .device__content {
      margin: 0;
    }
    .device__subtitle {
      position: relative;
      font-size: 16px;
      @include desktop {
        &:after {
          content: '';
          transform: rotate(-90deg);
          width: 24px;
          height: 24px;
          position: absolute;
          display: inline-block;
          right: -7px;
          top: -3px;
          background-image: url('./../../assets/icons/Icon_Purple_Bright_Chevron.svg');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          transition: right 0.15s ease-in-out;
        }
      }
    }
    .device__details {
      color: $grey-dark;
    }
  }
}
