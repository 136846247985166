.card__solved {
  font: 500 0.875rem/1.2 $font-secondary; /* 14/16 */
  color: $cochlear-black;
  width: 140px;
  height: 1.875rem; /* 30/16 */
  justify-content: left;
  align-items: flex-start;
  margin: 0;
  top: -1px;
  // gradient applied to bottom padding area of 2px.
  // solid white background applied to the rest
  padding-bottom: 2px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 2px;
  // added inline style, control % with GA data. eg; here is 70%
  // background: linear-gradient(to right, #237b02 0%, #237b02 70%, #dc0707 70%, #dc0707 100%);
  @include desktop {
    top: 7px;
  }
  &:before {
    background: url('./../../../assets/icons/sml-like.svg') no-repeat
      top;
    background-size: 14px auto;
    left: -3px;
  }
  &:after {
    background: url('./../../../assets/icons/sml-dislike.svg')
      no-repeat center;
    background-size: 14px auto;
    right: -3px;
  }
  &--mini {
    width: 53px;
    height: 0.7rem;
    top: 0;
    padding-bottom: 3px;
    background-size: 100% 3px;
    &:before {
      left: -27px;
      background-size: 12px auto;
    }
    &:after {
      right: -27px;
      background-size: 12px auto;
    }
  }
}
