%outline-shared {
  padding: 13px 22px 14px 29px;
  border: 1px solid transparent;

  &:hover,
  &:focus {
    padding: 12px 21px 13px 28px;
    border-width: 2px;
  }
}

a,
button,
input,
span {
  &.sdw__button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 0 none;
    border-radius: $radius-rounded;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    font-family: $font-header;
    font-size: 1.125rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    line-height: 1.166666666666667;
    padding: 14px 25px 15px 30px;
    text-align: center;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    @include mobile {
      display: block;
      width: 100%;
    }

    &::after {
      transition: left 0.15s ease-in-out;
      content: '';
      transform: rotate(-90deg);
      width: 24px;
      height: 24px;
      position: relative;
      display: inline-block;
      margin: -7px 0 0;
      top: 6px;
      left: 0;
      background-image: url('./../assets/icons/Icon_White_Chevron.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    &:hover,
    &:focus {
      &::after {
        left: 6px;
      }
    }

    &--has-no-icon {
      padding-right: 30px;
      text-align: center;
      min-width: 140px;

      &::after {
        content: none;
      }
    }

    &--block {
      display: block;
      width: 100%;
    }

    &--cochlear-yellow {
      background-color: $cochlear-yellow;
      border-color: $cochlear-yellow;
      color: $cochlear-black;

      &:hover,
      &:focus {
        background-color: $cochlear-yellow-hover;
      }

      &::after {
        background-image: url('./../assets/icons/Icon_Purple_Chevron.svg');
      }
    }

    &--cochlear-yellow-purple-text {
      background-color: $cochlear-yellow;
      border-color: $cochlear-yellow;
      color: $link-colour;

      &:hover,
      &:focus {
        background-color: $cochlear-yellow-hover;
      }

      &::after {
        background-image: url('./../assets/icons/Icon_Purple_Chevron.svg');
      }
    }

    &--purple {
      background-color: $link-colour;
      border-color: $link-colour;
      color: #fff;

      &:hover,
      &:focus {
        background-color: $link-hover;
      }

      &::after {
        background-image: url('./../assets/icons/Icon_White_Chevron.svg');
      }
    }

    &--white {
      background-color: #fff;
      border-color: #fff;
      color: $link-colour;

      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.85);
      }

      &::after {
        background-image: url('./../assets/icons/Icon_Purple_Chevron.svg');
      }
    }

    &--link {
      color: $link-colour;
      background: transparent;
      padding: 4px 5px 5px 10px;
      line-height: 1;
      display: inline-block;
      width: auto;

      span {
        display: inline-block;
        border-bottom: 1px solid transparent;
      }

      &:hover,
      &:focus {
        span {
          border-bottom-color: $link-colour;
        }
      }

      &::after {
        background-image: url('./../assets/icons/Icon_Purple_Chevron.svg');
      }

      &-white-text {
        color: #fff;

        &:hover,
        &:focus {
          span {
            border-bottom-color: #fff;
          }
        }

        &::after {
          background-image: url('./../assets/icons/Icon_White_Chevron.svg');
        }
      }

      &-back {
        padding: 4px 10px 5px 5px;

        &::after {
          content: none;
        }

        &::before {
          transition: left 0.15s ease-in-out;
          content: '';
          transform: rotate(90deg);
          width: 24px;
          height: 24px;
          position: relative;
          display: inline-block;
          margin: -7px 10px 0 0;
          top: 6px;
          left: 0;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-image: url('./../assets/icons/Icon_Purple_Chevron.svg');
        }

        &.btn--link-white-text {
          &::before {
            background-image: url('./../assets/icons/Icon_White_Chevron.svg');
          }
        }

        &:hover,
        &:focus {
          &::before {
            left: -6px;
          }
        }
      }
    }

    &.is-external-link {
      &::after {
        background-image: url('./../assets/icons/ext-link.svg');
        transform: rotate(0deg);
        background-size: 16px auto;
        margin: -7px 0 0 8px;
        top: 9px;
      }

      &:hover,
      &:focus {
        &::after {
          left: 0;
        }
      }
    }

    &--disabled {
      color: #8e8e8e;
      cursor: not-allowed;
      background-color: #e3e3e3;

      &::after {
        background-image: url('./../assets/icons/Icon_Grey_Chevron.svg');
        opacity: 0.6;
      }

      &:hover,
      &:focus {
        background-color: #e3e3e3;

        &::after {
          left: 0;
        }
      }
    }

    &-back {
      padding: 14px 35px 15px 28px;

      &::after {
        content: none;
      }

      &::before {
        transition: left 0.15s ease-in-out;
        content: '';
        transform: rotate(90deg);
        width: 24px;
        height: 24px;
        position: relative;
        display: inline-block;
        margin: -7px 10px 0 0;
        top: 6px;
        left: 0;
        background-image: url('./../assets/icons/Icon_Purple_Chevron.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      &:hover,
      &:focus {
        &::before {
          left: -6px;
        }
      }
    }

    &.btn--outline-cochlear-yellow {
      background-color: transparent;
      border-color: $cochlear-yellow;
      color: $cochlear-yellow;
      @extend %outline-shared;

      &::after {
        background-image: url('./../assets/icons/Icon_Yellow_Chevron.svg');
      }
    }

    &.btn--outline-purple {
      background-color: transparent;
      border-color: $link-colour;
      color: $link-colour;
      @extend %outline-shared;

      &::after {
        background-image: url('./../assets/icons/Icon_Purple_Chevron.svg');
      }
    }

    &.btn--outline-white {
      background-color: transparent;
      border-color: #fff;
      color: #fff;
      @extend %outline-shared;

      &::after {
        background-image: url('./../assets/icons/Icon_White_Chevron.svg');
      }
    }

    &.is-small {
      font-family: $font-secondary;
      padding: 6px 20px 8px 18px;
      font-size: 0.875rem;
      width: auto;
      display: inline-block;

      &::after {
        width: 16px;
        height: 16px;
        background-size: 20px auto;
        margin-left: 4px;
      }
    }
  }
}

/**  
 * submit button with spinner */

button[type='submit'].sdw__button {
  &.is-submitting {
    position: relative;
    padding-right: 38px;

    .lds-ring {
      display: inline-block;
      margin-left: 10px;
      margin-top: -12px;
      top: 8px;
    }

    &::after {
      content: none;
    }

    &.sdw__button--purple {
      .lds-ring div,
      .lds-ring span {
        border: 2px solid #fff;
        border-color: #fff transparent transparent;
      }

      &:active {
        .lds-ring div,
        .lds-ring span {
          border-color: #fff transparent transparent;
        }
      }
      @include desktop {
        &:hover {
          .lds-ring div,
          .lds-ring span {
            border-color: #fff transparent transparent;
          }
        }
      }
    }
  }

  &:active {
    .lds-ring div,
    .lds-ring span {
      border-color: $link-colour transparent transparent transparent;
    }
  }

  @include desktop {
    &:hover {
      .lds-ring div,
      .lds-ring span {
        border-color: $link-colour transparent transparent transparent;
      }
    }
  }
}

/* generic button/link with spinner */

.has-spinner {
  &.is-submitting {
    position: relative;
    padding-right: 38px;

    .lds-ring {
      display: inline-block;
      margin-left: 10px;
      margin-top: -12px;
      top: 8px;
    }

    &::after {
      content: none;
    }
  }

  &:active {
    .lds-ring div,
    .lds-ring span {
      border-color: $link-colour transparent transparent transparent;
    }
  }

  @include desktop {
    &:hover {
      .lds-ring div,
      .lds-ring span {
        border-color: $link-colour transparent transparent transparent;
      }
    }
  }
}

/* spinner */

.lds-ring {
  display: none;
  position: relative;
  width: 29px;
  height: 29px;

  span,
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 2px;
    border: 2px solid $link-colour;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $link-colour transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
