// @import "~bulma/sass/utilities/_all.sass";
// @import "./../../_sass/custom";

.section--support {
  background: $purple;
  padding: 2.25rem 1.5rem 2.75rem !important;
  @include desktop {
    padding: 1.25rem 1.5rem !important;
  }
  .support__content {
    text-align: center;
    align-items: center;
    justify-content: center;
    .title {
      color: #ffffff;
      padding-left: 0;
      padding-top: 3.25rem;
      width: 90%;
      min-width: 220px;
      margin: 0 auto 1rem;
      &:before {
        background: url('./../../assets/icons/clock.png') no-repeat
          center;
        background-size: 36px auto;
        height: 2.25rem;
        width: 2.25rem;
        left: 50%;
        top: 0;
        margin-left: -1.125rem;
      }
      @include desktop {
        font-size: 1.625rem;
        padding-left: 3rem;
        padding-top: 0;
        margin: 0 2.375rem 0 0;
        text-align: left;
        width: auto;
        min-width: 0;
        &:before {
          left: 0;
          margin-left: 0;
          top: 50%;
          margin-top: -1.125rem;
        }
      }
      @include widescreen {
        padding-top: 0;
        padding-left: 3.25rem;
        // font-size: 2rem;
        // line-height: 1.88;
      }
    }
    .sdw__button--white {
      width: auto;
      display: inline-block;
    }
  }
  &.section--device-landing-page {
    .sdw__button--white {
      background-color: $cochlear-yellow;
      border-color: $cochlear-yellow;
      color: $link-colour;
      &:hover,
      &:focus {
        background-color: $cochlear-yellow-hover;
      }
    }
  }
}
