@charset "utf-8";

// FONTS
// --------------------------------------------
@import './../fonts/fonts.scss';

// PROJECT COLORS AND FONTS
// --------------------------------------------
@import './custom';

$nav-height: 55px;
$nav-border-height: 3px;
$nav-desktop-height: 70px;

// Helpers
// -----------------------------------------------
.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  hyphens: auto;
}

// helper classes for positioning icons
.include-icon-before {
  position: relative;
  padding-left: 1.5rem;

  /* 24/16 */
  &:before {
    content: '';
    display: inline-block;
    height: 1.25rem;
    /*20/16*/
    width: 1.25rem;
    position: absolute;
    left: 0;
  }
}

.include-icon-after {
  position: relative;
  padding-right: 1.5rem;

  /* 24/16 */
  &:after {
    content: '';
    display: inline-block;
    height: 1.25rem;
    /*20/16*/
    width: 1.25rem;
    position: absolute;
    right: 0;
  }
}

// Hide GTM image scripts (IE11)
// jaavscript added 'display:none' not working in IE11
// ---------------------------------------------------
body > img[width='1'] {
  display: none;
}

// Larger Close Button
// ----------------------------------------------
.is-larger.delete,
.is-larger.modal-close {
  height: 40px;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
  width: 40px;
}

@import '~bulma/bulma';

@import './../components/ArticleIssue/ArticleIssue.scss';
@import './../components/ArticlesListing/ArticlesListing.scss';
@import './../components/ArticlesSection/ArticlesSection.scss';
@import './../components/Card/Card.scss';
@import './../components/Category/Category.scss';

@import './../components/custom/ArticleDate/ArticleDate.scss';
@import './../components/custom/ArticleViews/ArticleViews.scss';
@import './../components/custom/ArticleSolved/ArticleSolved.scss';
@import './../components/custom/ArticleFeedback/ArticleFeedback.scss';
@import './../components/custom/AvailabilityStatus/AvailabilityStatus.scss';
@import './../components/custom/CategoryLink/CategoryLink.scss';
@import './../components/custom/CountryLanguage/CountryLanguage.scss';
@import './../components/custom/DeviceLink/DeviceLink.scss';
@import './../components/custom/ErrorScreen/ErrorScreen.scss';
@import './../components/custom/AuthorizationError/AuthorizationError.scss';
@import './../components/custom/ChatSFHC/ChatSFHC.scss';
@import './../components/custom/MIAWChat/MIAWChat.scss';
@import './../components/custom/MyDeviceList/MyDeviceList.scss';
@import './../components/custom/ProfileDropdown/ProfileDropdown.scss';
@import './../components/custom/ModalNewFeatures/ModalNewFeatures.scss';
@import './../components/custom/Search/Search.scss';

@import './../components/DevicesRangeListing/DevicesRangeListing.scss';
@import './../components/Heading/Heading.scss';
@import './../components/Hero/Hero.scss';
@import './../components/InstructionalContent/InstructionalContent.scss';
@import './../components/LayoutSection/LayoutSection.scss';
@import './../components/LinkList/LinkList.scss';
@import './../components/Modal/Modal.scss';
@import './../components/TabsSection/TabsSection.scss';
@import './../components/ThumbnailList/ThumbnailList.scss';
@import './../components/ServiceRequestForm/ServiceRequestForm.scss';

@import './mobileNavbar.scss';
@import './desktopNavbar.scss';
@import './navItems.scss';
@import './footer.scss';

// Layout
// --------------------------------------------
.container {
  max-width: 1180px !important;
}

// Header Sizes
// --------------------------------------------
// borrows heavily from bulma's title class and also our variable modifiers in 'custom.scss'
.title {
  font-family: $font-header;

  &.is-1 {
    line-height: 1.18;

    @include tablet {
      font-size: 3rem; // 48px
      line-height: 1.15;
    }
  }

  &.is-2 {
    line-height: 1.25;

    @include tablet {
      font-size: 2.5rem; // 40px
    }
  }

  &.is-3 {
    line-height: 1.25;

    @include tablet {
      font-size: 1.875rem; // 30px
    }
  }

  &.is-4 {
    line-height: 1.36;
  }

  &.is-5 {
    line-height: 1.3;
  }
}

// Project Buttons
// --------------------------------------------
@import './buttons';

// Links
// --------------------------------------------
a {
  &.external__link {
    @extend .dont-break-out;

    &:after {
      content: '';
      background: url('./../assets/icons/ext-link.svg') no-repeat
        center;
      background-size: 12px auto;
      padding-left: 1rem;
      /* 20px */
      margin-left: 0.25rem;
      top: -1px;
      position: relative;
    }
  }
}

sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
  font-weight: 400;
}

sub {
  top: 0.4em;
}

// Mobile Safari Bottom Navigation Bar
// --------------------------------------------
@include mobile {
  html {
    /* Allows content to fill the viewport and go beyond the bottom */
    height: 100%;
    overflow-y: visible;

    &.is-clipped {
      body.site {
        overflow: hidden !important;
      }
    }
  }

  body.site {
    height: 100%;
  }
}

// Skip to main content
// --------------------------------------------
#skiplink {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  display: block;
  padding: 1em;
  outline: 0;

  &.is-sr-only-focusable:focus,
  &.is-sr-only-focusable:active {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

// Sticky footer
// --------------------------------------------
@include tablet {
  body.site,
  #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  .site__content {
    flex: 1 0 auto;
  }
}

.site__content {
  > .section {
    padding: 3rem 0.75rem;

    @include tablet {
      padding: 3rem 1.5rem;
    }

    + .section {
      padding: 0 0.75rem 3rem;

      @include tablet {
        padding: 0 1.5rem 3rem;
      }
    }
  }

  > .section--devices-article-issue {
    @include mobile {
      padding-top: 2rem;
    }
  }
}

// Universal Header
// --------------------------------------------
@include desktop {
  .hero {
    .navbar--universal {
      min-height: 50px;

      > .container {
        min-height: 45px;
      }

      background: #efefed;
      height: 50px;
      border-top: 5px solid $cochlear-yellow;

      .navbar-item,
      .navbar-link {
        font-weight: 300;
        color: $grey-dark;
        position: relative;
      }

      .navbar-item {
        padding: 0 22px;
        font-size: 0.875em;
        /* 14px */
        line-height: 2;

        &:last-child {
          padding-left: 11px;
          padding-right: 0.75rem;
        }

        &.is--active {
          background: $white;
        }

        &.is--primary {
          padding: 0 28px;
        }
      }

      .navbar-menu--primary {
        margin-right: 0 !important;

        .is-link {
          padding: 11px 1.5em;
          height: auto;
          border-radius: 0;
          font-size: 14px;
        }
      }
    }
  }
}

// Hero
// --------------------------------------------
.hero {
  > .is-scrolling-down {
    top: calc(-#{$nav-height} - #{$nav-border-height});

    @include desktop {
      top: 0;
    }
  }

  &--home {
    // background: url('./../assets/backgrounds/home-hero--mobile.jpg') no-repeat right 20px $link-colour;
    // background-size: contain;

    // @include desktop {
    //   background: url('./../assets/backgrounds/home-hero.jpg') no-repeat right 70% $link-colour;
    //   background-size: 70% auto;
    //   background-position: calc(100% + 100px) top;
    // }

    // @include widescreen {
    //   background-size: auto 116%;
    //   background-position: right top;
    // }

    // @include fullhd {
    //   background-size: 1040px auto;
    //   background-position: calc(50% + 360px) top;
    // }

    @include desktop {
      > .is-scrolling {
        .hero-head {
          background-color: rgba($link-colour, 0.975);
        }
      }

      .hero-head {
        background-color: rgba($link-colour, 0.75);
        transition: background-color 3s ease, box-shadow 2s ease;
      }
    }
  }

  &--default {
    @include desktop {
      > .is-scrolling {
        .hero-head {
          background: #fff;
        }
      }

      .hero-head {
        background-color: transparent;
        transition: background-color 0.2s ease, box-shadow 0.5s ease;
      }
    }
  }
}

// basic navbar
.navbar--basic {
  border-top: 5px solid $yellow;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: #fff;

  .navbar-brand {
    width: 100%;
  }

  .navbar-item {
    width: 110px;
    margin: 0 auto;
    padding: 0;
    border: 0;

    &:hover,
    &:focus {
      border: 0;
    }

    @include desktop {
      width: 130px;
      min-width: 130px;
      max-width: 130px;
    }

    img {
      position: relative;
      right: -10px;
      display: block;
      width: 100%;
      max-height: none;
    }
  }
}

// Links Inside Titles
// --------------------------------------------
.title {
  > a {
    color: $link-colour;

    &:hover,
    &:focus {
      color: $link-hover;
    }
  }
}

// Template styles
// --------------------------------------------
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  transition: top 0.2s ease-in-out;

  // when the profile dropdown is open
  @include touch {
    &.is-profile-open {
      top: 0 !important;

      .hero-head {
        background: #efefed;

        nav .wordmark {
          opacity: 0.5;
        }
      }

      .wordmark {
        .black {
          display: block !important;
        }

        .reversed {
          display: none !important;
        }

        &:before {
          background-image: url(./../assets/icons/Icon_Purple_Chevron.svg);
        }
      }
    }
  }
}

.hero-head {
  background: $purple;
  border-top: $nav-border-height solid $cochlear-yellow;

  nav {
    height: $nav-height;

    @include desktop {
      height: $nav-desktop-height;
    }

    .navbar-brand {
      min-height: $nav-height;

      @include desktop {
        min-height: $nav-desktop-height;
      }

      .navbar-end {
        justify-content: flex-end;
        margin-left: auto;
        align-items: center;
        display: flex;
      }
    }

    .wordmark {
      width: 38%;
      min-width: 150px;
      max-width: 200px;
      // margin: 0 auto;
      display: flex;
      align-items: center;

      > img {
        padding: 5px 8px;
      }

      &:before {
        background: url(./../assets/icons/Icon_White_Chevron.svg)
          no-repeat center;
        background-size: 26px auto;
        transform: rotate(90deg);
        margin: -1px 0 0 7px;
      }

      .hero--default & {
        &:before {
          background-image: url(./../assets/icons/Icon_Purple_Chevron.svg);
        }
      }
    }

    .brandmark {
      padding: 5px;
      margin-left: 1.5rem;
      width: 68px;
    }
  }

  @include desktop {
    // background: transparent;
    border-top: 0 none;

    nav {
      height: 70px;

      .navbar-brand {
        align-items: center;
      }
    }
  }
}

// Indent section
// --------------------------------------------
.section--indent-margin {
  @include desktop {
    margin-top: -8rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  > .container {
    position: relative;

    @include desktop {
      padding: 1.875rem 1.875rem 0;
      background: #fff;
      box-sizing: content-box; // padding added to the width
    }

    .device-common-articles {
      margin: 1rem 1rem 0;
    }
  }

  &.section--home {
    @include touch {
      margin-top: 0;

      > .container {
        background: #fff;
        margin-top: -6rem;

        > .title {
          border-top: 3px solid $primary;
          padding: 1rem 1rem 0;
        }
      }
    }

    @include desktop {
      margin-top: -11rem;
    }

    > .container {
      @include desktop {
        border-top: 3px solid $primary;
      }
    }
  }
}

.hero-body {
  &.hero--indent-next {
    @include desktop {
      padding-bottom: 10rem; // category section is raised so we create some room here to accomodate it
    }
  }
}

// Availablity Status
// --------------------------------------------

@mixin availability-status-parent {
  position: relative;
  padding-right: 30%;
  display: inline-block;
  width: 100%;
}

@mixin availability-status {
  position: absolute;
  padding-left: 26px;
  left: 72%;
  top: 50%;
  margin-top: -8px;

  @include tablet {
    font-size: 0.875rem;
  }
}

%availability-status-parent {
  @include availability-status-parent;
}

%availability-status {
  @include availability-status;
}

.show-availability {
  // margin / padding
  .is-coming-soon,
  .is-offline,
  .is-online {
    .availability-status {
      margin-top: 4px;
      white-space: nowrap;
    }
  }

  // circle left aligned
  .is-offline,
  .is-online {
    .availability-status {
      margin-left: 18px;
    }
  }

  .is-coming-soon,
  .is-offline {
    // style link text when disabled
    color: $disabled !important;
    cursor: not-allowed !important;

    > span {
      color: $disabled !important; // telephone number
    }

    &:hover,
    &:focus {
      color: $disabled !important;
    }
  }

  // style coming soon label
  .is-coming-soon {
    .availability-status--is-coming-soon {
      font: 400 1rem/1.2 $font-primary;
      color: $gold;
      // padding-left: 8px;
    }
  }

  // positioning on wider screens
  @include desktop {
    .is-coming-soon,
    .is-offline,
    .is-online {
      @include availability-status-parent;

      .availability-status {
        @include availability-status;
      }
    }
  }

  // apply to modal at tablet instead of desktop
  .modal-card-body & {
    @include tablet {
      .is-coming-soon,
      .is-offline,
      .is-online {
        @include availability-status-parent;

        .availability-status {
          @include availability-status;
        }
      }
    }
  }
}

// Animations and keyframes
// --------------------------------------------
@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  animation: #{$str};
}

// Hover effect
// --------------------------------------------
.hover-grow {
  transition: transform 0.35s ease-in-out;
  transform: perspective(1000px) translateZ(0);

  &:hover,
  &:focus {
    // box-shadow: 0 3px 30px rgba(0,0,0,.3);
    border-color: $link-colour;
    transition: transform 0.2s ease-in-out;
    transform: perspective(1000px) translateZ(21px);

    @include desktop {
      transform: perspective(1000px) translateZ(41px);
    }
  }
}

// 404 Not found
// ---------------------------------------------
.section__not-found {
  .not-found {
    @include tablet {
      width: 60%;
      max-width: 500px;
      text-align: center;
      margin: 0 auto;
    }

    &__image {
      width: 276px;
      height: 276px;

      @include tablet {
        margin: 0 auto;
      }
    }
  }

  .title {
    font: 700 1.375rem/1.27 $font-header;
    color: $link-colour;
    margin-top: 1rem;

    @include tablet {
      font-size: 2rem;
      margin-top: 2rem;
    }
  }

  p {
    font: 700 1.125rem/1.67 $font-primary;
    color: $grey-dark;
    margin-bottom: 1.125rem;

    @include tablet {
      font-size: 1.375rem;
      line-height: 1.55;
      margin: 0 auto 2rem;
    }
  }
}

// Spinner
// --------------------------------------------
.spinner {
  position: relative;

  .spinner--loading {
    display: block;
    width: 46px;
    height: 46px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -23px 0 0 -23px;

    &:after {
      @include animation('lds-dual-ring 1.2s linear infinite');
      content: ' ';
      display: block;
      width: 46px;
      height: 46px;
      margin: 1px;
      border-radius: 50%;
      border: 5px solid $grey-light;
      border-color: $grey-light transparent $grey-light transparent;
    }
  }
}

@include keyframes(lds-dual-ring) {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* -----------------------------
Slideshow Base
----------------------------- */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
  touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  .slick-slide {
    pointer-events: none;

    &.slick-current {
      pointer-events: auto;
    }
  }

  ul {
    &.slick-dots {
      margin: 0;
      padding: 0;
      width: auto;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      li {
        margin: 0;
        display: inline-block;
        padding: 0;
        width: 20px;
        height: 8px;
        cursor: pointer;
        position: relative;

        &.slick-active {
          button {
            &::before {
              background-color: #ffcb06;
            }
          }
        }

        button {
          font-size: 0;
          line-height: 0;
          display: block;
          padding: 4px;
          color: transparent;
          border: 0;
          outline: none;
          background: transparent;

          &::before {
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -4px;
            width: 8px;
            height: 8px;
            content: '';
            display: block;
            border-radius: 4px;
            background-color: #cdc9c5;
          }
        }
      }
    }
  }
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  align-items: stretch;
}

.slick-track::before,
.slick-track::after {
  content: ' ';
  display: table;
}

.slick-track::after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  min-height: 1px;
  display: none;
  padding-bottom: 50px;

  > div {
    height: 100%;
  }
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}
