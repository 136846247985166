// Navbar Menu
// -------------------------------------------
@include touch {
  .navbar-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
  }

  .navbar-start {
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.2);
    background: #fff;
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: stretch;

    > .navbar-item {
      text-align: center;
      height: 100%;
      flex-basis: 25%;
      flex-grow: 0;
    }
  }

  div.navbar-item > a.navbar-link,
  a.navbar-item {
    padding: 8px 0 0;

    &.mobile__icon {
      &::before {
        content: '';
        margin: 0 auto;
        width: 18px;
        height: 18px;
        display: block;
        background-image: url('./../assets/icons/mob-home.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% auto;
      }
    }

    &.mobile__icon--device {
      &::before {
        background-image: url('./../assets/icons/mob-device.svg');
      }
    }

    &.mobile__icon--chat {
      &::before {
        background-image: url('./../assets/icons/mob-chat.svg');
      }
    }

    &.mobile__icon--search {
      &::before {
        background-image: url('./../assets/icons/mob-search.svg');
      }
      &::after {
        content: none;
      }
    }
  }

  a,
  div {
    &.navbar-item {
      &:hover,
      &:focus {
        background: transparent;
      }

      .navbar-link {
        background: transparent;

        &:hover,
        &:focus {
          background: transparent;
        }
      }
    }
  }

  .navbar-item {
    color: $grey-dark;
    background: transparent;
    border-bottom: 3px solid transparent;
    font: 500 0.875rem/1.2 $font-header;
    text-align: center;

    &:hover {
      color: $link-colour;
      border-bottom: 3px solid $cochlear-yellow;
    }

    &.is-current-page,
    &.is-active,
    &.is-active > a {
      color: $link-colour;
      border-bottom: 3px solid $cochlear-yellow;
    }

    // circle size
    .availability-status {
      &::before {
        width: 8px;
        height: 8px;
      }
    }

    &.navbar-end {
      @include touch {
        padding: 0;
      }
    }

    .navbar-link {
      &:hover,
      &:focus {
        background: transparent;
      }
    }

    &.has-dropdown {
      .navbar-link {
        &::after {
          content: none;
        }
      }

      .navbar-dropdown {
        background-color: #efeeed;
        box-shadow: 0 -8px 8px rgba($black, 0.1);
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        position: fixed;
        z-index: -1;

        .is-coming-soon {
          .availability-status {
            display: none;
          }
        }
      }

      &.is-active {
        .navbar-dropdown {
          height: 100%;
          padding: 0;

          > .container {
            height: 100%;
          }

          .navbar-items {
            background-color: #efeeed;
            height: 100%;

            &--secondary {
              opacity: 0;
              left: 100%;
              visibility: hidden;
              transition: opacity 0.3s ease, visibility 0.3s ease,
                left 0.3s ease;
              position: absolute;
              width: 100%;
              top: 0;
              z-index: 1;

              &.is-active {
                opacity: 1;
                left: 0;
                visibility: visible;
              }

              .navbar-item {
                padding-left: 20px;
              }
            }
          }

          // nav menu
          .navbar-item--menu-title {
            background: $cochlear-black;
            height: 70px;
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: none;
            padding-left: 12px;
            padding-right: 4px;

            &:hover,
            &:focus {
              color: #fff;
            }
          }

          .navbar-item--menu-sub-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: none;
            padding-left: 12px;
            padding-right: 4px;
            background: #fff;
            color: $label-color;
            height: 60px;
            font-family: $font-header;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: -0.29px;
            line-height: 1.2;
            border-bottom: 3px solid $yellow-highlight;

            &:hover,
            &:focus {
              color: $label-color;
            }
          }

          .btn-back {
            color: #fff;
            background: transparent;
            border: 0 none;
            text-align: left;
            font: 700 1.125rem/1.1 $font-header;
            cursor: pointer;

            &::before {
              background-image: url('./../assets/icons/Icon_White_Chevron.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100% auto;
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }

  .login-btn {
    border: 1px solid rgb(239, 238, 237);
    border-radius: 14px;
    padding: 2px 15px 4px 16px;
    color: #fff;
    font: 300 1em/1.1875 $font-primary;
    text-align: center;
    text-decoration: none;
    margin: 0 10px 0 0;

    &:hover,
    &:focus {
      color: #fff;
    }

    .hero--default & {
      border: 1px solid rgb(239, 238, 237);
      color: rgb(63, 20, 130);
    }
  }
}
