.navbar-dropdown {
  opacity: 0;
  transition: all 0.5s ease;

  .navbar-item {
    width: 100%;
    border: 0 none;
    min-height: 60px;
    color: $cochlear-black;
    font: 700 1.125rem/1.1 $font-header;
    padding-top: 19px;
    padding-bottom: 18px;
    padding-left: 63px;
    padding-right: 37px;
    box-shadow: 0 1px 0 0 #cdc9c5;
    text-align: left;
    display: flex;
    align-items: center;
    background: #fff;
    margin-bottom: 1px;

    &.include-icon-after {
      &::after {
        right: 12px;
      }
    }

    &.include-icon-before {
      &::before {
        left: 22px;
        width: 25px;
        height: 25px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    @include touch {
      &.icon-link {
        >div {
          >span {
            font: 400 0.875rem/1.1 $font-primary;
          }
        }
      }
    }

    &.nucleus-8 {
      &::before {
        left: 18px;
        width: 33px;
        height: 33px;
        background: url('./../assets/devices/nucleus-8@2x.png') no-repeat center;
        background-size: 33px auto;
      }
    }

    &.nucleus-7 {
      &::before {
        left: 18px;
        width: 33px;
        height: 33px;
        background: url('./../assets/devices/nucleus-7@2x.png') no-repeat center;
        background-size: 33px auto;
      }
    }

    &.nucleus-6 {
      &::before {
        left: 18px;
        width: 33px;
        height: 33px;
        background: url('./../assets/devices/nucleus-6@2x.png') no-repeat center;
        background-size: 33px auto;
      }
    }

    &.kanso {
      &::before {
        left: 18px;
        width: 33px;
        height: 33px;
        background: url('./../assets/devices/kanso-2@2x.png') no-repeat center;
        background-size: 33px auto;
      }
    }

    &.baha {
      &::before {
        left: 18px;
        width: 33px;
        height: 33px;
        background: url('./../assets/devices/baha-6-max@2x.png') no-repeat center;
        background-size: 33px auto;
      }
    }

    &.osia-2 {
      &::before {
        left: 18px;
        width: 33px;
        height: 33px;
        background: url('./../assets/devices/osia-2@2x.png') no-repeat center;
        background-size: 33px auto;
      }
    }

    &.i-cant-see-my-device {
      &::before {
        background: url('./../assets/icons/help.svg') no-repeat center;
        background-size: 25px auto;
      }
    }

    &.icon-call {
      display: block;

      &::before {
        background: url(./../assets/icons/call.svg) no-repeat center;
        background-size: 23px auto;
      }

      >.phone {
        color: $purple;
        white-space: nowrap;
      }
    }

    &.icon-chat {
      display: block;

      &::before {
        width: 26px;
        background: url(./../assets/icons/chat.svg) no-repeat center;
        background-size: 26px auto;
      }
    }

    &.icon-service {
      &::before {
        background: url(./../assets/icons/submit-service-r.svg) no-repeat center;
        background-size: 22px auto;
      }
    }

    &.icon-enquiry {
      &::before {
        background: url(./../assets/icons/enquiry.svg) no-repeat center;
        background-size: 25px auto;
      }
    }

    &.icon-upgrade {
      &::before {
        background: url(./../assets/icons/upgrade.svg) no-repeat center;
        background-size: 18px auto;
      }
    }

    &.icon-user-guides {
      &::before {
        background: url(./../assets/icons/user-guides.svg) no-repeat center;
        background-size: 20px auto;
      }
    }

    &.icon-link {
      &::after {
        background: url(./../assets/icons/Icon_Purple_Chevron.svg) no-repeat center;
        transform: rotate(-90deg);
        background-size: 26px auto;
        top: 50%;
        margin-top: -9px;
      }
    }

    &.icon-external {
      &::after {
        background: url(./../assets/icons/ext-link.svg) no-repeat center;
        background-size: 16px auto;
      }
    }

    &.is-offline {
      &.icon-call {
        &::before {
          background-image: url(./../assets/icons/call_disabled.svg);
        }
      }

      &.icon-chat {
        &::before {
          background-image: url(./../assets/icons/chat_disabled.svg);
        }
      }
    }

    &.is-coming-soon {
      &.icon-service {
        &::before {
          background-image: url(./../assets/icons/inact-device-repair.svg);
        }
      }

      &.icon-link {
        &::after {
          content: none;
        }
      }

      @include desktop {
        .availability-status {
          right: 12px;
          left: auto;
        }
      }
    }
  }
}

.is-active {
  >.navbar-dropdown {
    opacity: 1;
    transform: translateY(0);
    display: block;
  }
}