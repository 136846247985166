.device-common-articles {

  &.device-all-articles,
  &.device-category-articles {
    .columns {
      .column {
        @include tablet {
          box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
          padding: 1.5rem 0 2.5rem;
        }

        .articles__list__item {
          @include tablet {
            padding: 0.5rem 2.5rem 0 2.5rem;

            +.articles__list__item {
              padding-top: 1rem;
            }
          }
        }
      }
    }
  }

}

.articles__list__item {
  &--hero {
    background-color: #FFFDF6;
    padding-bottom: 1.75rem !important;
    border-bottom: 1px solid #EFEEED;


    .articles__list__item__image {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 56px;
      margin-right: .5em;

      @media only screen and (min-width: 30em) {
        flex-basis: 112px;
      }
    }

    .articles__list__item__content {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .articles__list__item__details {
      font-size: 18px;
      line-height: 1.444444444444444;
      margin-top: .5em;

      a.sdw__button--link {
        margin-top: .5em;
        padding-left: 0;
      }
    }


    +.articles__list__item {
      border-top: 0 none !important;
      margin-top: 0 !important;
    }
  }

  &__title {
    margin-bottom: 0.25rem;

    @include tablet {
      margin-bottom: 0.125rem;
    }

    .title {
      >a {
        font: 700 1.125rem/1.44 $font-header;

        display: inline-block;

        @include tablet {
          font: 700 1.375rem/1.36 $font-header;
        }
      }
    }
  }

  &__statistics {
    padding: 0.5rem 1.5rem;
    margin-bottom: 0.75rem;

    @include tablet {
      margin-bottom: 0;
    }
  }

  &__details {
    font: 400 0.875rem/1.42857 $font-secondary;
  }

  +.articles__list__item {
    border-top: 1px solid #efeeed;
    margin-top: 1.75rem;
    padding-top: 1rem;
  }
}

// Articles listing
// Layout specific
// -------------------------------------

.articles__list__item {
  @include tablet {
    display: flex;
    // ------------
    // flex-direction: row;  // now that stats have been removed this is overdone.
    // flex-wrap: wrap;
    // ----------------
    flex-direction: column;
  }

  &--hero {
    display: flex;

    @include tablet {
      flex-direction: row;
    }
  }


  &__title,
  &__details {
    @include tablet {
      flex-grow: 1; // force these to take up all available space
      // ------------
      // flex-basis: 75%; // up to 80% at most
      // now that stats have been removed this is overdone.
      // ----------------

      flex-shrink: 1; // allow these to also shrink if required, or if title has multiple lines.
    }
  }

  &__title {
    @include tablet {
      margin-right: 1rem;
    }
  }

  &__statistics {
    @include tablet {
      flex-shrink: 0; // cant shrink
      align-self: flex-end; // aligned to the end
      text-align: right;
    }
  }

  &__details {
    display: flex;
    flex-wrap: wrap; // wrap on a narrow device where date and group details cant fit on the one line
    align-items: center;

    @include tablet {
      flex-basis: auto;
    }

    &__group {
      flex-shrink: 0; // group never wraps, it will shift onto the next line if it cant all fit
      margin-top: 0.25rem;
    }
  }
}