// @import "~bulma/sass/utilities/_all.sass";
// @import "../_sass/custom";

.section {
  +.section {
    &.section--devices-help-articles {
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
      margin-bottom: 3rem;
    }
  }

  &.section--devices-help-articles {
    .column {
      &.column--bgImage {
        @include mobile {
          background-image: none !important;
        }

        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
        position: relative;

        &:after {
          top: 0;
          content: '';
          display: block;
          right: 0;
          width: 999999px;
          height: 100%;
          position: absolute;
          z-index: -1;
        }

        @include desktop {
          margin-left: -25%;
        }

        @include widescreen {
          margin-left: -50%;
          background-position: 110px bottom;
        }
      }
    }

    &--nucleus-8 {
      .column--bgImage {
        &:after {
          opacity: 0.29;
          background-image: linear-gradient(to bottom,
              rgba(255, 255, 255, 0.5),
              #c4b5a9),
            linear-gradient(to bottom, #ffffff, #ffffff);
        }
      }
    }

    &--nucleus-7 {
      .column--bgImage {
        &:after {
          opacity: 0.29;
          background-image: linear-gradient(to bottom,
              rgba(255, 255, 255, 0.5),
              #c4b5a9),
            linear-gradient(to bottom, #ffffff, #ffffff);
        }
      }
    }

    &--nucleus-6 {
      .column--bgImage {
        &:after {
          background-image: linear-gradient(to bottom,
              rgba(255, 255, 255, 0.5),
              #e3e3e3);
        }
      }
    }

    &--baha-5,
    &--osia-2,
    &--kanso,
    &--kanso-2 {
      .column--bgImage {
        &:after {
          opacity: 0.16;
          background-image: linear-gradient(to bottom,
              rgba(255, 255, 255, 0.5),
              #c4b5a9),
            linear-gradient(to bottom, #cdc9c5, #cdc9c5);
        }
      }
    }

    .column__padding {
      padding: 0 1.5rem;

      @include tablet {
        padding: 1rem;
      }

      @include desktop {
        padding: 2rem 3rem 0 2rem;
      }
    }

    h2.title {
      margin-top: 1.5rem;

      @include tablet {
        margin-top: 0;
        font-size: 1.875rem;
      }
    }

    .column--footer {
      height: auto;
      border: 0;
      border-radius: 0;
      border-top: 1px solid #efeeed;
      // box-shadow: 0 4px 9px -4px rgba(0, 0, 0, 0.13);
      margin-top: 2rem;
      padding: 0;
      display: block;

      @include tablet {
        padding-right: 999em;
        margin-right: -999em;
        // padding-right: 253px;
        // margin-right: -253px;
      }

      >a {
        text-align: left;
        color: $link-colour;
        font-size: 20px;
        line-height: 1.5;
        font-weight: 700;
        display: block;
        padding: 19px 60px 22px calc(2rem - 3px);
        border-left: 3px solid $primary;
        position: relative;

        @include tablet {
          margin-right: 1rem;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 30px;
          margin: -8px 0 0 0;
          width: 20px;
          height: 20px;
          display: block;
          background: url('./../../assets/icons/Icon_Purple_Chevron.svg') no-repeat 0 0;
          transform: rotate(-90deg);
          transition: all 0.2s ease-in-out;
        }

        &:hover,
        &:focus {
          color: $link-hover;

          &:after {
            right: 24px;
          }
        }
      }
    }
  }
}

// Narrow article listing on category and device pages
// ------------------------------------------------------
main.site__content {
  >.section--devices-help-articles:first-child {

    // container is narrower than normal on this template layout only
    >.container {

      // around 70%. ~860/1220 design size
      @include desktop {
        // from 1024px
        // max-width: 960px;
        // width: 960px;
        max-width: 860px;
        width: 860px;
      }

      @include widescreen {
        // from 1216px
        // max-width: 1152px;
        // width: 1152px;
        max-width: 900px;
        width: 900px;
      }

      @include fullhd {
        // from 1408px
        // max-width: 1344px;
        // width: 1344px;
        max-width: 940px;
        width: 940px;
      }
    }
  }
}