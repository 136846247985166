// Header
// --------------------------------------------
@include desktop {
  .hero {
    > .is-scrolling {
      .hero-head {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);

        .navbar--support {
          border-bottom-color: transparent;
        }
      }
    }

    .hero-head {
      transition: box-shadow 0.5s ease;

      .navbar {
        border-bottom: 1px solid #e6e6e6;
      }
    }

    .navbar-start {
      width: 100%;
      > .navbar-item {
        font: 600 1.125rem/1.2 $font-primary;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        &.has-dropdown {
          padding-left: 0;
          padding-right: 0;
        }

        .navbar-link:not(.is-arrowless) {
          padding-left: 2.25em;
          padding-right: 3em;

          &:after {
            transition: all 0.2s ease-in-out;
            transform-origin: center;
            right: 1.625em;
          }
        }

        &.site-title {
          font: 700 1.5rem/1.2 $font-header;
          padding-left: 1em;
          padding-right: 1em;
          margin-left: 0.5rem;

          &:before {
            content: '';
            width: 1px;
            height: 20px;
            background: rgba(#fff, 0.4);
            left: -1em;
            position: relative;
          }

          &:hover,
          &:focus {
            background: transparent;
          }
        }
      }
    }

    .navbar-end {
      .icon {
        width: 40px;
        height: 40px;
        background-color: #fdc82f;
        border: 0 none;
        border-radius: 50%;
        cursor: pointer;
        &:hover,
        &:focus {
          background-color: #ffb000;
        }
        &:after {
          display: block;
          width: 40px;
          height: 40px;
          top: 0;
        }
        &.icon--search {
          &:after {
            background-image: url('./../assets/icons/mob-search.svg');
            background-size: 23px;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
        &.icon--close {
          &:after {
            background-image: url('./../assets/icons/Icon_Purple_Close.svg');
            background-size: 18px;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }
    }

    // style the active dropdown item
    .navbar-start > .navbar-item.has-dropdown {
      .navbar-link {
        &:hover,
        &:focus {
          background: transparent;

          &:before {
            content: '';
            height: 3px;
            left: 2.25em;
            right: 1.5em;
            top: 51px;
            background: $yellow-highlight;
            position: absolute;
            transition: top 0.2s ease-in, right 0.2s ease-in,
              left 0.2s ease-in;
          }
        }
      }

      &.is-active {
        .navbar-link {
          background: #fff;
          color: $link-colour;
          border-top: 3px solid transparent;
          padding-top: 5px;

          &:before {
            top: -3px;
            left: 0;
            right: 0;
            background: $yellow-highlight;
            position: absolute;
            content: '';
            height: 3px;
            display: block;
          }

          &:after {
            transform: rotate(-225deg);
            margin-top: -0.15em;
          }
        }
      }
    }

    // used by articles pages
    &--default {
      .navbar-start > .navbar-item.site-title {
        color: $cochlear-black;

        &:before {
          background: rgba(205, 201, 197, 0.4);
        }
      }
    }

    &--home,
    &--devices {
      > .is-scrolling {
        .navbar--support {
          border-bottom-color: transparent;
        }
      }

      .hero-head {
        .navbar {
          border-bottom: 1px solid #581ca5;
        }
      }

      .navbar-start > .navbar-item,
      .navbar-start > .navbar-item > .navbar-link {
        color: #fff;
      }
    }

    &--category {
      .navbar-start > .navbar-item,
      .navbar-start > .navbar-item > .navbar-link {
        color: #fff;
      }

      &--sound {
        > .is-scrolling {
          .navbar--support {
            border-bottom-color: transparent;
          }
        }

        .hero-head {
          .navbar {
            border-bottom: 1px solid #007daa;
          }
        }
      }

      &--usage-and-care {
        > .is-scrolling {
          .navbar--support {
            border-bottom-color: transparent;
          }
        }

        .hero-head {
          .navbar {
            border-bottom: 1px solid #00ada3;
          }
        }
      }

      &--apps-and-accessories,
      &--accessories {
        > .is-scrolling {
          .navbar--support {
            border-bottom-color: transparent;
          }
        }

        .hero-head {
          .navbar {
            border-bottom: 1px solid #a4006b;
          }
        }
      }

      &--power-and-batteries {
        > .is-scrolling {
          .navbar--support {
            border-bottom-color: transparent;
          }
        }

        .hero-head {
          .navbar {
            border-bottom: 1px solid #b42934;
          }
        }
      }
    }
  }
}

// Desktop Nav
// --------------------------------------------
@include desktop {
  .hero-head {
    position: relative;

    > .container,
    > .container .navbar,
    > .container .navbar .navbar-start > .navbar-item,
    > .container .navbar .navbar-item--menu-title {
      position: static;
    }

    > .container
      .navbar
      .navbar-start
      > a.navbar-item:not(.site-title) {
      position: relative;

      &:hover,
      &:focus {
        background: transparent;

        &:before {
          content: '';
          height: 3px;
          left: 1.5rem;
          right: 1.5rem;
          top: 51px;
          background: $yellow-highlight;
          position: absolute;
        }
      }
    }
  }
}

// The Desktop Dropdown
// ------------------------------------------
@include desktop {
  .navbar-dropdown {
    padding: 0;
    border: 0;
    margin-top: -1px;
    z-index: 21;

    &:before,
    &:after {
      content: '';
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
    }

    &:before {
      background: #fff;
      left: 0;
    }

    &:after {
      background: #f6f5f5;
      right: 0;
    }

    .navbar-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .navbar-items {
        flex: 0 0 auto;
        width: 50%;
        padding: 40px 80px 62px;

        .navbar-item {
          border-left: 3px solid transparent;
          padding-left: 60px;
          width: 100%;
          background: transparent;
          border-top: 0;
          border-right: 0;

          &--menu-title {
            font: 700 1.875rem/1.2 $font-header;
            color: $label-color;
            padding-left: 0;
            padding-right: 0;
            white-space: normal;
          }
        }

        // secondary level nav
        &--secondary {
          opacity: 0;
          left: calc(50% - 30px);
          visibility: hidden;
          transition: left 0.5s ease, visibility 0.5s ease,
            opacity 0.5s ease;
          position: absolute;
          width: 50%;
          top: 0;
          z-index: 1;

          &.is-active {
            opacity: 1;
            left: 50%;
            visibility: visible;
          }

          .icon-link {
            padding-left: 20px;
          }
        }

        button.navbar-item,
        a.navbar-item {
          &.is-active,
          &:hover,
          &:focus {
            color: $link-colour;
            border-left: 3px solid $yellow-highlight;
            cursor: pointer;
            background: transparentize(#efeeed, 0.5);
          }
        }

        + .navbar-items {
          align-self: flex-start;

          &.navbar-items-support {
            align-self: center;
          }
        }

        &.show-availability {
          .navbar-item {
            padding-right: 100px;
            white-space: normal;

            &--menu-title {
              padding-right: 0;
            }
          }
        }

        .support__content {
          margin: 0 auto;
          text-align: center;

          &:before {
            content: '';
            display: block;
            width: 45px;
            height: 45px;
            background: url('./../assets/icons/clock_grey.png');
            background-size: 100% auto;
            margin: 0 auto 1rem;
          }

          .title {
            padding-left: 0;
            font: 500 1.875rem/1.33 $font-header;
            text-align: center;
            color: $label-color;
          }
        }
      }

      button.delete {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
        background: transparent;

        &:before,
        &:after {
          background: #8b8d8e;
        }

        &:hover,
        &:focus {
          border: 1px solid rgba(#8b8d8e, 0.25);

          &:before,
          &:after {
            background: $grey-dark;
          }
        }
      }
    }
  }
}

@include widescreen {
  .navbar-dropdown {
    .navbar-container {
      .navbar-items {
        padding: 40px 102px 62px 20px;

        + .navbar-items {
          padding: 40px 80px 62px;
        }
      }
    }
  }
}
