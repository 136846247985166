.article__feedback {
  // force level display to stack until desktop
  flex-flow: column;
  @include desktop {
    flex-flow: row;
  }
  @include touch {
    .level-left + .level-right {
      margin-top: 1.5rem;
    }
  }
  .button {
    font: 700 1rem/1.2 $font-header;
    color: #ffffff;
    padding: 0.625rem 1.5rem 0.625rem 3rem;
    height: auto;
    &:before {
      background-size: 18px auto;
      left: 18px;
    }
    &.is-success {
      &:before {
        background: url('./../../../assets/icons/like-solve.svg')
          no-repeat center;
      }
    }
    &.is-danger {
      &:before {
        background: url('./../../../assets/icons/dislike-solve.svg')
          no-repeat center;
      }
    }
  }
}
