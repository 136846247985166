.article__date {
  font: 400 0.875rem/1.4 $font-secondary;
  color: $label-color;
  margin-top: 0.25rem;
}

// Default layout for list items
// ------------------------------
.article__date {
  flex-shrink: 0; // date never wraps
  margin-right: 0.5rem;
  &__label {
    display: none; // label text is hidden on mobile
    @include tablet {
      display: inline;
    }
  }
  &__year {
    @include tablet {
      display: none; // year is hidden on desktop
    }
  }
}

// Article page
// -------------------------------
.article__header__details {
  @include tablet {
    margin-top: 1em;
  }
  @include desktop {
    margin-top: 0;
  }
  .article__date {
    &__label {
      display: inline;
    }
    &__year {
      display: none;
    }
  }
}
