.country-language {
  display: flex;

  &__flag {
    width: 25px;
    height: 25px;
  }

  &__language-label {
    margin-left: 7px;
    text-transform: capitalize;
  }
}
