.section--service-request {
  .img-container {
    width: 100%;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .generic {
    background-image: url('./../../../assets/devices/generic.png');
  }

  .osia-2 {
    background-image: url('./../../../assets/devices/osia-2/osia-2.png');
  }

  .baha-4 {
    background-image: url('./../../../assets/devices/baha-4/baha-4.png');
  }

  .kanso-2 {
    background-image: url('./../../../assets/devices/kanso-2/kanso-2.png');
  }

  .freedom {
    background-image: url('./../../../assets/devices/freedom/freedom.png');
  }

  .baha-5 {
    &--black {
      background-image: url('./../../../assets/devices/baha-5/baha-5-black.png');
    }

    &--blonde {
      background-image: url('./../../../assets/devices/baha-5/baha-5-blonde.png');
    }

    &--brown {
      background-image: url('./../../../assets/devices/baha-5/baha-5-brown.png');
    }

    &--copper {
      background-image: url('./../../../assets/devices/baha-5/baha-5-copper.png');
    }

    &--default {
      background-image: url('./../../../assets/devices/baha-5/baha-5-default.png');
    }

    &--silver {
      background-image: url('./../../../assets/devices/baha-5/baha-5-silver.png');
    }
  }

  .baha-5-power {
    &--black {
      background-image: url('./../../../assets/devices/baha-5-power/baha-5-power-black.png');
    }

    &--blonde {
      background-image: url('./../../../assets/devices/baha-5-power/baha-5-power-blonde.png');
    }

    &--brown {
      background-image: url('./../../../assets/devices/baha-5-power/baha-5-power-brown.png');
    }

    &--copper {
      background-image: url('./../../../assets/devices/baha-5-power/baha-5-power-copper.png');
    }

    &--default {
      background-image: url('./../../../assets/devices/baha-5-power/baha-5-power-default.png');
    }

    &--silver {
      background-image: url('./../../../assets/devices/baha-5-power/baha-5-power-silver.png');
    }
  }

  .baha-5-superpower {
    &--carbon {
      background-image: url('./../../../assets/devices/baha-5-superpower/baha-5-superpower-carbon.png');
    }

    &--default {
      background-image: url('./../../../assets/devices/baha-5-superpower/baha-5-superpower-default.png');
    }

    &--maize {
      background-image: url('./../../../assets/devices/baha-5-superpower/baha-5-superpower-maize.png');
    }

    &--mocha {
      background-image: url('./../../../assets/devices/baha-5-superpower/baha-5-superpower-mocha.png');
    }

    &--smoke {
      background-image: url('./../../../assets/devices/baha-5-superpower/baha-5-superpower-smoke.png');
    }
  }

  .baha-6-max {
    &--black {
      background-image: url('./../../../assets/devices/baha-6-max/baha-6-max-black.png');
    }

    &--blonde {
      background-image: url('./../../../assets/devices/baha-6-max/baha-6-max-blonde.png');
    }

    &--brown {
      background-image: url('./../../../assets/devices/baha-6-max/baha-6-max-brown.png');
    }

    &--copper {
      background-image: url('./../../../assets/devices/baha-6-max/baha-6-max-copper.png');
    }

    &--default {
      background-image: url('./../../../assets/devices/baha-6-max/baha-6-max-default.png');
    }

    &--mint {
      background-image: url('./../../../assets/devices/baha-6-max/baha-6-max-mint.png');
    }

    &--silver {
      background-image: url('./../../../assets/devices/baha-6-max/baha-6-max-silver.png');
    }
  }

  .kanso {
    &--black {
      background-image: url('./../../../assets/devices/kanso/kanso-black.png');
    }

    &--chocolate-brown {
      background-image: url('./../../../assets/devices/kanso/kanso-chocolate-brown.png');
    }

    &--copper-brown {
      background-image: url('./../../../assets/devices/kanso/kanso-copper-brown.png');
    }

    &--default {
      background-image: url('./../../../assets/devices/kanso/kanso-default.png');
    }

    &--golden-blonde {
      background-image: url('./../../../assets/devices/kanso/kanso-golden-blonde.png');
    }

    &--sandy-blonde {
      background-image: url('./../../../assets/devices/kanso/kanso-sandy-blonde.png');
    }

    &--silver {
      background-image: url('./../../../assets/devices/kanso/kanso-silver.png');
    }

    &--slate-grey {
      background-image: url('./../../../assets/devices/kanso/kanso-slate-grey.png');
    }

    &--white {
      background-image: url('./../../../assets/devices/kanso/kanso-white.png');
    }
  }

  .nucleus-8 {
    &--black {
      background-image: url('./../../../assets/devices/nucleus-8/nucleus-8-black.png');
    }

    &--brown {
      background-image: url('./../../../assets/devices/nucleus-8/nucleus-8-brown.png');
    }

    &--default {
      background-image: url('./../../../assets/devices/nucleus-8/nucleus-8-default.png');
    }

    &--grey {
      background-image: url('./../../../assets/devices/nucleus-8/nucleus-8-grey.png');
    }

    &--sand {
      background-image: url('./../../../assets/devices/nucleus-8/nucleus-8-sand.png');
    }

    &--silver {
      background-image: url('./../../../assets/devices/nucleus-8/nucleus-8-white.png');
    }

    &--white {
      background-image: url('./../../../assets/devices/nucleus-8/nucleus-8-white.png');
    }
  }

  .nucleus-7 {
    &--black_gold {
      background-image: url('./../../../assets/devices/nucleus-7/nucleus-7-black\,-gold.png');
    }

    &--black {
      background-image: url('./../../../assets/devices/nucleus-7/nucleus-7-black.png');
    }

    &--brown {
      background-image: url('./../../../assets/devices/nucleus-7/nucleus-7-brown.png');
    }

    &--default {
      background-image: url('./../../../assets/devices/nucleus-7/nucleus-7-default.png');
    }

    &--grey {
      background-image: url('./../../../assets/devices/nucleus-7/nucleus-7-grey.png');
    }

    &--sand {
      background-image: url('./../../../assets/devices/nucleus-7/nucleus-7-sand.png');
    }

    &--white {
      background-image: url('./../../../assets/devices/nucleus-7/nucleus-7-white.png');
    }
  }

  .nucleus-6 {
    &--carbon {
      background-image: url('./../../../assets/devices/nucleus-6/nucleus-6-carbon.png');
    }

    &--default {
      background-image: url('./../../../assets/devices/nucleus-6/nucleus-6-default.png');
    }

    &--maize {
      background-image: url('./../../../assets/devices/nucleus-6/nucleus-6-maize.png');
    }

    &--mocha {
      background-image: url('./../../../assets/devices/nucleus-6/nucleus-6-mocha.png');
    }

    &--smoke {
      background-image: url('./../../../assets/devices/nucleus-6/nucleus-6-smoke.png');
    }

    &--white {
      background-image: url('./../../../assets/devices/nucleus-6/nucleus-6-white.png');
    }
  }

  .nucleus-5 {
    &--black {
      background-image: url('./../../../assets/devices/nucleus-5/nucleus-5-black.png');
    }

    &--brown {
      background-image: url('./../../../assets/devices/nucleus-5/nucleus-5-brown.png');
    }

    &--charcoal {
      background-image: url('./../../../assets/devices/nucleus-5/nucleus-5-charcoal.png');
    }

    &--default {
      background-image: url('./../../../assets/devices/nucleus-5/nucleus-5-default.png');
    }

    &--sand {
      background-image: url('./../../../assets/devices/nucleus-5/nucleus-5-sand.png');
    }

    &--white {
      background-image: url('./../../../assets/devices/nucleus-5/nucleus-5-white.png');
    }
  }

  .device__list {
    @include mobile {
      max-width: 240px;
    }

    .is-active {
      .device {
        border: solid 1px $yellow-highlight;
        background-color: rgba(255, 203, 6, 0.15);
      }
    }

    .has-open-sr {
      .device {
        opacity: 0.66;
        background-color: #f2f2f2;
        border: solid 1px #b1a3c7;
        position: relative;

        &__open-sr-msg {
          position: absolute;
          top: 9px;
          right: 11px;
          font-size: 12px;
          line-height: 1.17;
          letter-spacing: -0.07px;
          text-align: right;
          color: $grey-dark;
          width: 90px;
          padding-left: 1.25rem;

          &::before {
            background: url('./../../../assets/icons/error_disabled.svg')
              no-repeat center;
            background-size: 12px auto;
            top: -3px;
            opacity: 0.66;
          }
        }
      }
    }

    .device {
      border-radius: 0;
      padding: 1.125rem 1rem 1rem;
      cursor: pointer;
      flex-flow: column nowrap;
      align-items: flex-start;
      text-align: left;
      width: 100%;
      background: #fff;

      .device__image {
        margin-top: 1rem;
        width: 75px;
        height: 75px;
        flex: 0 0 75px;
        margin-bottom: 1.375rem;

        @include desktop {
          margin-top: 0.5rem;
        }
      }

      .device__content {
        margin-left: 0;
        margin-bottom: 0.8rem;
        display: block;

        .title {
          margin-bottom: 0.25rem;
          line-height: 1.1;

          &::after {
            content: none;
          }
        }

        .subtitle {
          color: $purple;
          line-height: 1.1;
          margin-top: 0;
          margin-bottom: 0.25rem;
        }
      }

      &.support__option {
        .device__content {
          margin-bottom: 0;
          padding-right: 0;
          margin-top: 0;
        }
      }

      &.device--help {
        flex-flow: row nowrap;
        align-items: center;
        border-radius: $radius-rounded;
        justify-content: center;

        @include tablet {
          border-radius: 0;
        }

        .device__image {
          width: 30px;
          height: 30px;
          flex: 0 0 30px;
          margin: 0 1rem 0 0;
        }

        .device__content {
          width: auto;
          display: block;
        }
      }
    }

    .device__subtitle {
      font: 400 0.875rem/1.2 $font-secondary;
      color: $grey-dark;

      .table {
        background: transparent;
      }

      .table td,
      .table th {
        padding: 0.3125em 0;
        border: 0;
      }

      .table th {
        padding-right: 0.5em;
      }
    }

    .warranty__label {
      td {
        color: $aa-green;
      }
    }

    .warranty-expired {
      .warranty__label {
        td {
          color: $aa-red;
        }
      }
    }
  }
}
