.article__views {
  font: 0.875rem/1.43 $font-secondary; /* 14/16 ; 20/14 */
  color: #797571;
  padding-left: 1.75rem; /* 28/16 */
  &::before {
    background: url('./../../../assets/icons/views.svg') no-repeat
      center;
    background-size: 19px auto;
    top: -1px;
  }
}
