html.is-search {
  height: 100%;
  overflow-y: hidden;

  body {
    height: 100%;
  }
}

.search {
  @extend .overlay;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  height: calc(
    100vh - 50px
  ); // on mobile 50px is the height of the mobile fixed nav on the bottom of the screen
  z-index: 30;
  bottom: 0;
  position: fixed;

  @include desktop {
    height: calc(
      100vh - 120px
    ); // on desktop 120px is the height of the uh and support nav
    position: relative;
  }

  &__background {
    @extend .overlay;
    background-color: rgba(0, 0, 0, 0.7);
  }

  &__wrapper {
    position: relative;
    width: 100%;
    flex: 0 1 auto;
    display: flex;
    flex-flow: column nowrap;
    min-height: 0;
  }

  &__container {
    display: flex;
    max-width: 1180px !important;
    margin: 0 auto;
    padding: 0 0.5rem;
    position: relative;

    @include desktop {
      max-width: 960px;
    }

    @include widescreen {
      max-width: 1152px;
    }

    @include fullhd {
      max-width: 1344px;
    }
  }

  &__form {
    padding: 2rem 0.5rem;
    background-color: $cochlear-yellow;

    &__input {
      font-size: 30px;
      line-height: 30px;
      width: 100%;
      background-color: transparent;
      border: 0;
      margin: 0;
      border-bottom: 1px solid hsla(0, 0%, 62%, 0.23);
      color: $grey-dark;
      font-family: $font-primary;
      outline: 0;
      appearance: none;
      -webkit-appearance: none;
      border-radius: 0;
      padding-left: 0;
      @include desktop {
        font-size: 50px;
        line-height: 50px;
      }
    }
    &__underline {
      font-size: 30px;
      height: 3px;
      overflow: hidden;
      position: absolute;
      bottom: -1px;
      border-bottom: 3px solid $link-colour;
      @include desktop {
        font-size: 50px;
      }
    }
    &__button[type='submit'] {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      @include desktop {
        position: relative;
        top: 10px;
      }
      cursor: pointer;
      &.icon {
        width: 40px;
        height: 40px;
        background-color: $cochlear-yellow;
        border: 0 none;
        border-radius: 50%;
        &:after {
          display: block;
          width: 40px;
          height: 40px;
          top: 0;
        }
        &.icon--search {
          &:after {
            background-image: url('./../../../assets/icons/Search_Charcoal.svg');
            background-size: 30px;
            background-repeat: no-repeat;
            background-position: center center;
          }
          &:hover,
          &:focus {
            &:after {
              background-image: url('./../../../assets/icons/mob-search.svg');
            }
          }
        }
      }
    }
  }

  &__list {
    background: #fff;
    flex-grow: 1;
    overflow: auto;
    min-height: calc(
      100vh - 154px
    ); // on mobile 154px is the height of the mobile fixed nav on the bottom of the screen and the yellow search bar
    @include desktop {
      min-height: calc(
        100vh - 247px
      ); // on desktop 247px is the height of the uh and support nav and yellow search bar
    }
    &--empty {
      height: 0;
      min-height: 0;
    }

    ul {
      list-style: none;
      padding: 10px 0 0;
      margin: 0 auto;

      @include desktop {
        max-width: 730px;
      }
    }

    &__item {
      padding: 10px 30px;
      margin: 0;
      border-bottom: 1px solid #efefef;
      &:first-child {
        margin-top: 0;
      }

      &__link {
        text-decoration: none;
        display: block;
        padding: 10px;
        transition-duration: 0.15s;
        transition-property: color, background-color, border-color;

        &:hover,
        &:focus {
          background-color: $grey-lighter;
          .search__list__item__link__title {
            text-decoration: underline;
          }
        }

        &__title {
          color: $link-colour;
          margin-bottom: 5px;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          &:first-child {
            margin-top: 0;
          }
        }
        &__snippet {
          font-size: 18px;
          line-height: 24px;
          color: $grey-dark;
        }
      }
    }

    &__pagination {
      display: flex;
      justify-content: center;

      margin: 40px 0 10rem;
      .search__container {
        align-items: center;
        .pagination__indicator {
          margin: 0 1em;
        }
      }

      button {
        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
  }
}
