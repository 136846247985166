// COLOURS
// --------------------------------------------

// Set your brand colors
$cochlear-yellow: #fdc82f;
$cochlear-yellow-hover: #ffb000;
$yellow-highlight: #ffcb06;
$cochlear-black: #1e1e1e;
$link-colour: #3f1482;
$link-hover: #672ac5;
$aa-red: #dc0707;
$aa-green: #237b02;

$grey-darker: $cochlear-black;
$grey-dark: #56565a;
$grey-light: #cccccc;
$grey-lighter: #efefef;
$white-ter: #F6F6F6;

// Update Bulma's global variables

// Colours
// ---------------------------------
$primary: $cochlear-yellow;
$yellow: $cochlear-yellow;
$link: $link-colour;
$dark: $grey-darker;
$cool-grey: $grey-dark;
$purple: $link-colour;
$success: $aa-green;
$danger: #DB8000;
$gold: #9f5f06;
$off-white: #e4e4e4;
$disabled: #948a85;
$label-color: #55565a;
$label-weight: 400;
$control-radius: 0;

// Fonts
// ---------------------------------
$font-primary: 'BlissPro-Light', 'Trebuchet MS', 'Gill Sans', 'Helvetica Neue', Arial, sans-serif;
$font-secondary: 'Helvetica Neue', Arial, sans-serif;
$font-header: 'BlissPro-Regular', 'Trebuchet MS', 'Gill Sans', 'Helvetica Neue', Arial, sans-serif; // apply a bold font weight for titles where required
$family-sans-serif: $font-primary;

// Font Sizes
// ---------------------------------
// Sized for mobile. Responsive helpers in main.scss
$size-1: 2.125rem;    // 34/16
$size-2: 2rem;        // 32/16
$size-3: 1.5rem;      // 24/16
$size-4: 1.375rem;    // 22/16 
$size-5: 1.25rem;     // 20/16 

// Button component
// ---------------------------------
$radius-large: 7px;

// Responsive Gap - bulma bug fix
// ---------------------------------
$gap: 32px;

// Title component
// ---------------------------------
$title-color: $cochlear-black;
$title-weight: 700; 
$title-line-height: 1.25;

$subtitle-weight: 700; 
$subtitle-size: 1.125rem;
$subtitle-line-height: 1.777777777777778;


// Navbar component
// ---------------------------------
$navbar-dropdown-arrow: $cochlear-yellow;

// Tab component
// ---------------------------------
$tabs-border-bottom-color: transparent;
$tabs-boxed-link-active-background-color: $cochlear-yellow;
$tabs-link-color: $cochlear-black;
$tabs-link-active-color: $cochlear-black;
$tabs-boxed-link-radius: 0;
$tabs-boxed-link-active-border-color: transparent;

// Footer component
// ---------------------------------
$footer-padding: 0;

// Input component
// ---------------------------------
$input-color: $cochlear-black;
$input-border-color: #cdc9c5;
$input-shadow: none;
$input-focus-color: $cochlear-black;
$input-radius: 0;