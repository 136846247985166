// Footer
// --------------------------------------------
.footer {
  &__sitemap {
    padding: 3.75rem 0 4rem;
    margin: 0;
    overflow: hidden;

    > .container > .columns {
      margin-bottom: 0;
    }

    @include tablet {
      padding: 4.5rem 1.5rem 5rem;
    }

    .columns .columns .column:first-child .menu {
      border-top: 0 none;
    }

    .column {
      padding: 0 0.75rem;

      @include tablet {
        padding: 0.75rem;
      }

      @include mobile {
        .columns {
          &.colums--support-menu {
            .column {
              &:first-child {
                ul {
                  padding-bottom: 0;
                }
              }

              &:last-child {
                ul {
                  padding-top: 0;
                }
              }
            }
          }
        }
      }
    }

    hr {
      background-color: #cdc9c5;
      height: 1px;

      @include tablet {
        margin: 2rem 0;
      }
    }

    &__header {
      padding: 0 0.7rem;

      @include tablet {
        padding: 0;
      }

      .title {
        color: #000000;
        margin: 0 0 1rem;
        // @include tablet {
        //   font: 700 1.875rem/2 $font-header;
        // }
      }

      a {
        font: 400 0.9375rem/1.2 $font-header;

        @include tablet {
          font-size: 1rem;
        }
      }

      // position: relative;
      // padding-left: 1.5rem; /* 24/16 */
      // &:before {
      //   content: '';
      //   display: inline-block;
      //   height: 1.25rem; /*20/16*/
      //   width: 1.25rem;
      //   position: absolute;
      //   left: 0;
      // }

      .breadcrumb {
        ul {
          align-items: center;

          li + li {
            padding-left: 1.5rem;
            position: relative;

            &:before {
              content: '';
              display: inline-block;
              background: url('./../assets/icons/Icon_Purple_Chevron.svg')
                no-repeat center;
              background-size: 100% auto;
              height: 1.25rem;
              width: 1.25rem;
              transform: rotate(-90deg);
              position: absolute;
              left: 0;
              top: 0;
            }
          }

          li {
            @include mobile {
              margin-bottom: 0.5rem;
            }

            a {
              padding: 0 0.25em;
            }

            &.is-active {
              span {
                color: $link;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 230px;
              }
            }
          }
        }
      }
    }

    .disclaimer {
      padding: 1rem 0.7rem 0;

      @include tablet {
        padding: 1rem 0 0;
      }
    }

    .menu {
      border-top: solid 1px #cdc9c5;

      @include tablet {
        border-top: 0 none;
      }

      .title {
        padding: 1rem 0.7rem;
        margin: 0;

        @include tablet {
          padding: 0;
          margin: 0 0 0.5rem;
        }

        &:first-letter {
          text-transform: capitalize;
        }

        > div {
          position: relative;
          padding-right: 1.5rem;

          /* 24/16 */
          &:after {
            content: '';
            display: inline-block;
            height: 1.25rem;
            /*20/16*/
            width: 1.25rem;
            position: absolute;
            right: 2px;
            top: 3px;
            background: url('./../assets/icons/Icon_Purple_Chevron.svg')
              no-repeat center;
            background-size: 24px auto;
            transition: all 0.2s ease-in-out;
            transform-origin: center;
          }

          @include tablet {
            padding-right: 0;

            &:after {
              content: none;
            }
          }
        }

        @include mobile {
          > div[aria-expanded='true'] {
            color: $link-colour;
          }
        }

        > div[aria-expanded='false'] {
          &:after {
            transform: rotate(-90deg);
          }
        }
      }

      @include tablet {
        .accordion__panel {
          display: block !important;
        }
      }

      ul {
        padding: 0.5rem 0.7rem;

        @include tablet {
          padding: 0;
        }

        margin: 0;

        > li {
          padding: 0.5em 0;

          @include tablet {
            padding: 0.5em;
            margin: 0 -0.5em;
          }

          a {
            font: 400 0.9375rem/1.2 $font-header;
            color: $grey-dark;

            @include desktop {
              font-size: 1rem;
            }

            > .phone {
              color: $link-colour;
              font-weight: 600;
              white-space: nowrap;
            }

            &:hover,
            &:focus {
              color: $grey-darker;
            }

            &.help__link {
              color: $link-colour;
              line-height: 20px;
              display: inline-block;

              &:before {
                background: url('./../assets/icons/help.svg')
                  no-repeat center;
                background-size: 20px auto;
              }

              &:hover,
              &:focus {
                color: $link-hover;
              }
            }
          }
        }
      }
    }
  }

  &__cochlear {
    background: #51515a;
    padding: 3.75rem 1.25rem;

    .cochlear-logo {
      width: 180px;
      height: 180px;
      margin: 0 auto;
      position: relative;

      > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__links {
      display: flex;
      flex-flow: column nowrap;

      @include desktop {
        flex-flow: row nowrap;
        width: 90%;
        margin: 1rem 0 0 2rem;

        .col-a {
          flex-grow: 2; // twice as wide as the last column
        }
      }

      li > a {
        font: 500 1.125rem/1.2 $font-primary;
        color: #fff;
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      .col-a,
      .col-b {
        padding-top: 1rem;
        padding-left: 1rem;

        @include desktop {
          padding-top: 0;
        }

        li {
          margin-top: 0.5rem;
        }
      }

      .col-a {
        padding-top: 0;

        > ul {
          display: flex;
          flex-flow: row nowrap;

          > li {
            flex: 1 0 auto;
          }
        }

        .col-a-a,
        .col-a-b {
          margin-top: 0;
        }
      }
    }

    .social-media {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;

      @include desktop {
        margin-top: 1rem;
      }

      li {
        padding: 10px;

        a {
          display: block;
        }
      }

      &__icon {
        background-repeat: no-repeat;
        background-size: 100%;
        height: 30px;
        width: 30px;
        display: block;
      }
    }
  }

  &__copyright {
    padding: 2rem 1.5rem 5.125rem; // compensate for the mobile fixed nav

    @include desktop {
      padding: 2rem 1.5rem;
    }

    p {
      font: 400 0.875rem/1.71 $font-secondary;
      color: #fff;
    }
  }
}
