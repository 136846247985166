@mixin indentArrow {
  content: '';
  display: block;
  position: absolute;
  left: 60px;
  top: -6px;
  margin-left: -20px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 16px solid $purple;
}

%indentArrow {
  @include indentArrow;
}

.category--columns {
  position: relative;

  &.section--indent-margin {
    // arrow
    &::before {
      @extend %indentArrow;
      @include tablet {
        left: 75px;
      }
      @include desktop {
        content: none;
      }
    }

    > .container {
      @include desktop {
        &::before {
          @include indentArrow;

          left: 50%;
          top: 0;
          border-top: 12px solid $purple;
        }
      }
    }
  }

  .column {
    > a {
      display: block;
      height: 100%;
    }
  }

  .media {
    align-items: center;
    border: 1px solid #b1a3c7;
    border-radius: $radius-rounded;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0.125rem 2rem;
    @include desktop {
      border-radius: 0;
      padding: 0 0 0 1rem;
      min-height: 110px;
    }

    &-left {
      width: 36px;
      @include desktop {
        width: 44px;
        margin-right: 0.5rem;
      }
    }

    &-content {
      color: $purple;
      font-size: 5vw;
      font-weight: $weight-semibold;
      line-height: 1.3125;
      padding: 1rem 0.5rem 1rem 0;
      @include desktop {
        font-size: $size-5;
        padding: 0.75rem 1rem 0.75rem 0;
      }

      p {
        display: block;
      }

      span {
        font: 400 1rem/1.3125 $font-primary;
        display: inline-block;
        color: $grey-dark;
      }
    }
  }
}
