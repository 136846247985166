%disabled-button {
  color: $cochlear-black;
  opacity: 0.6;
  box-shadow: none;
  cursor: not-allowed;
  background-color: #cacaca;
}

.card {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  &-image {
    padding-top: 20px;
  }

  &-header {
    box-shadow: none !important;

    &-title {
      padding: 0.75rem 1.75rem;
      font: 700 1.375rem/1.27 $font-header;
    }
  }

  &-content {
    padding-top: 0;
    flex-grow: 1;

    p {
      line-height: 1.44;
      color: $grey-dark;
      font: 400 1.125rem/1.44 $font-primary;
    }
  }

  &-footer {
    border-top: 0;
    padding: 0 1.5rem 1.5rem;
    @include tablet {
      align-self: center;
    }
  }
}

.section--help-columns {
  position: relative;

  &::after {
    bottom: 55%;
    content: '';
    display: block;
    width: 100%;
    height: 170px;
    background: url(./../../assets/backgrounds/bg-mobile.png)
      no-repeat bottom right;
    position: absolute;
    z-index: -2;
    right: 0;
    @include desktop {
      bottom: 60%;
      height: 474px;
      background: url(./../../assets/backgrounds/bg.png) no-repeat
        bottom right;
    }
  }

  .card {
    .availability-status {
      position: absolute;
      top: 20px;
      left: 40px;
    }

    .card-header-title {
      @include widescreen {
        margin: 0 auto;
        flex-grow: 0;
        flex-basis: 70%;
      }
    }
  }

  .subtitle {
    font: 500 1.125rem/1.56 $font-primary;
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
  }

  figure {
    &.image {
      width: 148px;
      height: 148px;
      margin: 0 auto;
    }
  }

  .call__link {
    font: 700 1.125rem/1.2 $font-header;
    margin: 0 auto;
    padding: 14px 23px 15px 30px;

    &::before {
      background: url('./../../assets/icons/call.svg') no-repeat
        center 2px;
      background-size: 18px auto;
    }
  }

  // disabled cards
  .is-offline {
    .call__link,
    .sdw__button {
      //mix-blend-mode: luminosity;
      @extend %disabled-button;

      &:hover,
      &:focus {
        &::after {
          left: 0;
        }
      }
    }

    .call__link {
      background-color: transparent;

      &::before {
        background-image: url('./../../assets/icons/call_disabled.svg');
      }
    }

    .sdw__button {
      &::after {
        background-image: url('./../../assets/icons/Icon_Grey_Chevron.svg');
      }
    }
  }

  .is-coming-soon {
    .sdw__button {
      @extend %disabled-button;

      padding: 14px 50px 15px;

      &::after {
        content: none;
      }
    }
  }
}
