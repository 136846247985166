.section--service-request {
  padding-top: 0 !important;
  .title {
    color: $purple;
    &.has-text-future {
      color: #93959e;
    }
  }
  @include mobile {
    .sdw__button {
      font-size: 1rem;
      padding: 14px 13px 15px 20px;
    }
  }
  ol {
    margin: 0 0 0 20px;
    list-style: none;
    counter-reset: li;
  }
  li.step__panel {
    margin: 0;
    padding: 0 0 20px 40px;
    @include desktop {
      padding: 0 0 60px 60px;
    }
    counter-increment: li;
    position: relative;
    border-left: 2px solid #3f1482;
    max-width: 660px;
    > .title {
      padding-right: 4rem; // create space for the edit button
    }
    .step__panel__inner {
      margin: 1rem 0 3rem;
      .help {
        float: right;
        color: $grey-dark;
        font-size: 12px;
      }
    }
  }
  li.step__panel:first-child {
    padding-top: 0;
    margin-top: 0;
    max-width: none;
  }
  li.step__panel:before {
    left: -20px;
    content: counter(li);
    background: #3f1482;
    border-radius: $radius-rounded;
    color: white;
    width: 40px;
    height: 40px;
    text-align: center;
    display: block;
    position: absolute;
    font: 700 1.375rem/40px $font-header;
  }
  .edit__button {
    margin-right: -4rem;
    float: right;
    color: $link;
    font-weight: 700;
    text-decoration: none;
    position: relative;
    padding-right: 2rem;
    &:after {
      background: url('./../../assets/icons/edit.svg') no-repeat
        center;
      background-size: 18px auto;
      right: 7px;
    }
  }

  .field--email {
    @include tablet {
      width: calc(50% - 0.375rem);
    }
  }

  .field--mobile-group {
    > .field-body {
      @include tablet {
        flex-basis: calc(90% - 0.375rem);
        flex-grow: 0;
      }
    }
  }

  .field--type {
    @include tablet {
      flex-grow: 0;
    }
  }

  .field--code {
    @include tablet {
      flex-basis: 125px;
      flex-shrink: 0;
      flex-grow: 1;
    }
  }

  .field--number {
    @include tablet {
      flex-shrink: 1;
      flex-grow: 1;
    }
  }

  .field--problem-topic {
    .select {
      width: 100%;
      @include tablet {
        width: 50%;
      }
    }
  }

  // full width select
  .select {
    > select {
      width: 100%;
    }
  }
  .confirmation {
    .title {
      color: $cochlear-black;
    }
    h3.title {
      margin-bottom: 1rem;
    }
    &__intro {
      @include desktop {
        width: 65%;
        margin-top: 3rem;
      }
      &__image {
        width: 81px;
        padding-bottom: 2rem;
      }
    }
    hr {
      background-color: transparent;
      margin: 2rem 0;
      @include tablet {
        background-color: #efeeed;
        margin: 3rem 0;
        height: 1px;
      }
    }
    &__description {
      line-height: 1.75;
      margin-bottom: 3rem;
    }
  }

  // form input
  .label,
  input,
  select,
  textarea {
    font-family: $font-header;
  }
  ::placeholder {
    color: #666666;
  }
  .input {
    padding: 12px 19px 14px;
    line-height: 23px;
    height: 49px;
    border: 1px solid $input-border-color;
    @include desktop {
      padding: 8px 11px 9px;
      line-height: 23px;
      height: 40px;
    }
    &:focus {
      box-shadow: none;
      padding-bottom: 13px;
      border-bottom: 2px solid $yellow-highlight;
      @include desktop {
        padding-bottom: 8px;
      }
    }
    // &:read-only {
    //   color: $cochlear-black;
    //   background-size: 24px auto;
    //   background: #efeeed url('./../../assets/icons/lock.svg') no-repeat right 10px center;
    //   padding-right: 45px;
    // }
  }
  [readonly].input {
    color: $cochlear-black;
    background-size: 24px auto;
    background: #efeeed url('./../../assets/icons/lock.svg') no-repeat
      right 10px center;
    padding-right: 45px;
  }
  .field {
    &.has-addons {
      .button.is-static {
        color: $cochlear-black;
        padding-top: 12px;
        padding-bottom: 14px;
        line-height: 23px;
        height: 49px;
        border-color: $input-border-color;
        @include desktop {
          padding-top: 8px;
          padding-bottom: 9px;
          line-height: 23px;
          height: 40px;
        }
      }
      .input {
        border-left-color: #efeeed;
      }
    }
  }
  .textarea {
    padding: 12px 19px 14px;
    border: 1px solid $input-border-color;
    @include desktop {
      padding: 8px 11px 9px;
    }
    &:focus {
      box-shadow: none;
      padding-bottom: 13px;
      border-bottom: 2px solid $yellow-highlight;
      @include desktop {
        padding-bottom: 8px;
      }
    }
  }
  .select {
    height: 49px;
    select {
      padding: 12px 40px 14px 19px;
      line-height: 23px;
      height: 49px;
      border: 1px solid $input-border-color;
      &:focus {
        box-shadow: none;
        padding-bottom: 13px;
        border-bottom: 2px solid $yellow-highlight;
        @include desktop {
          padding-bottom: 8px;
        }
      }
    }
    &.is-readonly {
      > select {
        color: $cochlear-black;
        background-size: 24px auto;
        background: #efeeed url('./../../assets/icons/lock.svg')
          no-repeat right 10px center;
        padding-right: 45px;
      }
      &:after {
        content: none;
      }
    }
    @include desktop {
      height: 40px;
      select {
        padding: 8px 40px 9px 11px;
        height: 40px;
      }
    }
  }

  .control--checkbox {
    margin-bottom: 15px;
    padding-left: 30px;
    .checkbox {
      position: static;
    }
    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
    .control__indicator {
      position: absolute;
      top: 2px;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 3px;
      border: solid 1px $input-border-color;
      background-color: #ffffff;
      @include desktop {
        width: 18px;
        height: 18px;
      }
    }
  }

  /* Hover and focus states */
  .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    background: #ffffff;
    border: solid 1px #68bd49;
  }

  /* Checked state */
  .control input:checked ~ .control__indicator {
    background: #ffffff;
    border: solid 1px #68bd49;
  }

  /* Hover state whilst checked */
  .control:hover input:not([disabled]):checked ~ .control__indicator,
  .control input:checked:focus ~ .control__indicator {
    background: #ffffff;
  }

  /* Disabled state */
  .control input:disabled ~ .control__indicator {
    pointer-events: none;
    opacity: 0.6;
    background: #e6e6e6;
  }

  /* Check mark */
  .control__indicator:after {
    position: absolute;
    display: none;
    content: '';
  }

  /* Show check mark */
  .control input:checked ~ .control__indicator:after {
    display: block;
  }

  /* Checkbox tick */
  .control--checkbox .control__indicator:after {
    top: 2px;
    left: 7px;
    width: 8px;
    height: 15px;
    transform: rotate(35deg);
    border: solid #68bd49;
    border-width: 0 3px 3px 0;
    @include desktop {
      top: 2px;
      left: 5px;
      width: 6px;
      height: 11px;
      border-width: 0 3px 3px 0;
    }
  }

  /* Disabled tick colour */
  .control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #68bd49;
  }

  .terms {
    &__title {
      margin-top: 2rem;
      color: $cochlear-black;
      font-size: 1.125rem;
      line-height: 1.33;
    }
    ul > li,
    p {
      font-size: 1rem;
      line-height: 1.38;
      margin-bottom: 0.875rem;
      padding-left: 1rem;
    }
    ul {
      list-style: none;
      > li {
        padding-left: 1rem;
        &:before {
          content: '\2022';
          color: $yellow-highlight;
          font-weight: bold;
          display: inline-block;
          width: 1rem;
          margin-left: -1rem;
        }
      }
    }
  }
  label {
    &.checkbox {
      font-family: $font-primary;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.38;
      color: $cochlear-black;
    }
  }
  .field--checkbox {
    &.has--error {
      label {
        color: $cochlear-black;
      }
      .control__indicator {
        border-color: $aa-red;
      }
    }
  }

  // validation
  // ----------------------------------
  .has--error {
    label {
      color: $aa-red;
    }
    .input {
      background-size: 15px auto;
      background: transparent url('./../../assets/icons/error.svg')
        no-repeat right 10px center;
      padding-right: 45px;
    }
    .input,
    .select select,
    .select .react-select__control,
    .textarea {
      border-color: $aa-red;
    }
    .error__message {
      margin-top: 6px;
      color: $aa-red;
      font: 400 1rem/1.1875 $font-header;
    }
    .field.has-addons {
      .button.is-static,
      .input {
        border: 1px solid $aa-red;
      }
      .button.is-static {
        border-right-color: transparent;
      }
      .input {
        border-left-color: transparent;
      }
    }
  }

  .react-select-container {
    height: 49px;
    .react-select__control {
      border-radius: 0;
      height: 49px;
      padding: 11px 40px 13px 19px;
      border: 1px solid $input-border-color;
      &:hover {
        cursor: pointer;
      }
      &--is-focused {
        box-shadow: none;
        padding-bottom: 12px;
        border-bottom: 2px solid $yellow-highlight;
        @include desktop {
          padding-bottom: 7px;
        }
      }
      .react-select__value-container {
        padding: 0;
        > div {
          margin: 0;
          padding: 0;
        }
      }
      .react-select__placeholder,
      .react-select__single-value {
        line-height: 23px;
        color: $cochlear-black;
        margin: 0;
      }
      .react-select__single-value {
        .name {
          display: none;
        }
        b {
          font-weight: 400;
        }
        // sibling div with dynamic class name
        + div {
          padding: 0;
          margin: 0;
        }
      }
    }
    .react-select__indicators {
      display: none;
    }
    .flag-icon {
      margin-right: 10px;
    }
    @include desktop {
      height: 40px;
      .react-select__control {
        padding: 7px 40px 8px 11px;
        line-height: 23px;
        height: 40px;
      }
    }
  }
}
