.section--identify-serial-number {
  .level {
    .image {
      width: 80px;
      padding: 10px;
      border: solid 1px #efeeed;
      background-color: #fff;
      position: relative;
      @include tablet {
        width: 110px;
        padding: 12px;
      }
    }
  }

  .highlight-spot {
    width: 4px;
    height: 4px;
    background-color: #ffcb06;
    border-radius: 3px;
    position: absolute;
    @include tablet {
      width: 6px;
      height: 6px;
      margin: -1px 0 0 -1px;
    }
  }

  .image {
    &.nucleus-7 {
      .highlight-spot {
        left: 65%; 
        top: 52.5%; /* 42/80 */
      }
    }

    &.nucleus-6 {
      .highlight-spot {
        left: 71.25%; /* 57px; */
        top: 46.25%; /* 37px; */
      }
    }

    &.nucleus-5 {
      .highlight-spot {
        left: 23.75%;
        top: 48.75%;
      }
    }        

    &.kanso {
      .highlight-spot {
        left: 52.5%;
        top: 56.25%;
      }
    }           

    &.baha-5 {
      .highlight-spot {
        left: 62.5%;
        top: 62.5%;
      }
    }

    &.baha-5-power {
      .highlight-spot {
        left: 60%;
        top: 62.5%;
      }
    }

    &.baha-5-superpower {
      .highlight-spot {
        left: 25%;
        top: 45%;
      }
    }        
  }
}